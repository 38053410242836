import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import { PublicBanner, PublicFooter } from "./components";

const PublicLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#c9cce9",
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const PublicLayoutWrapper = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
});

const PublicLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const PublicLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const PublicLayout = () => {
  return (
    <PublicLayoutRoot>
      <PublicBanner />
      <PublicLayoutWrapper>
        <PublicLayoutContainer>
          <PublicLayoutContent>
            <Outlet />
            <PublicFooter />
          </PublicLayoutContent>
        </PublicLayoutContainer>
      </PublicLayoutWrapper>
    </PublicLayoutRoot>
  );
};

export default PublicLayout;
