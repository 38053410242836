import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Stack,
  Snackbar,
  SnackbarContent,
  Button,
  Typography,
  Link,
  Slide,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useOrcidURL } from "../../hooks/orcid";
import { SOCIETY_EMAIL } from "../../theme/societies";

// unimported

const name = "IES_CONFERENCE_COMMUNITY_ORCID_TEMPORARY_BANNER";
const initialValue = 1;
const maxValue = 3;
const expires = 3650;

function TransitionUp(props) {
  return (
    <Slide {...props} direction="left" timeout={{ enter: 500, exit: 500 }} />
  );
}

export default function InfoBanner() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const hasOrcidId = !!user.orcidId;

  const { data } = useOrcidURL(open);

  const society = process.env.REACT_APP_IEEE_SOCIETY;

  useEffect(() => {
    if (
      !hasOrcidId &&
      (!Cookies.get(name) ||
        (Cookies.get(name) && parseInt(Cookies.get(name)) < maxValue))
    ) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotShowAgain = () => {
    Cookies.set(name, maxValue);
    handleClose();
  };

  const handleAccept = () => {
    const currentValue = parseInt(Cookies.get(name));

    if (currentValue) {
      Cookies.set(name, currentValue + 1, {
        expires,
      });
    } else {
      Cookies.set(name, initialValue, {
        expires,
      });
    }

    handleClose();
  };

  const action = (
    <Box sx={{ mt: 2 }}>
      <Button
        sx={{ mr: 3 }}
        color="light"
        size="small"
        onClick={() => handleNotShowAgain()}
      >
        DO NOT SHOW AGAIN
      </Button>
      <Button
        color="corporate"
        variant="contained"
        size="small"
        component={"a"}
        href={data?.link}
        disabled={!data?.link}
        onClick={() => handleAccept()}
      >
        ACCEPT
      </Button>
    </Box>
  );

  const message = (
    <>
      <Typography
        variant="h6"
        color="text.corporate"
        align="justify"
        gutterBottom
      >
        New ORCID feature available!
      </Typography>
      <Typography variant="body2">
        Authorize Conferences Community to use your ORCID record by the
        following steps or just clicking the ACCEPT button:
      </Typography>
      <Box sx={{ mt: 2, px: 2 }}>
        <Typography variant="body2" align="justify">
          -{" "}
          <Link
            component={RouterLink}
            color="#8089cb"
            to="/app/general/account"
          >
            Go to your user profile
          </Link>
          .
        </Typography>
        <Typography variant="body2" align="justify">
          - Click the ORCID authorization button.
        </Typography>
        <Typography variant="body2" align="justify">
          - Login into your ORCID account and accept Conferences Community to
          use your records.
        </Typography>
      </Box>
      <Typography sx={{ mt: 2 }} variant="body2" align="justify">
        Do not forget to make visible your ORCID profile. This will allow us to
        find users information (e.g finding reviewers, co-authors, etc). If you
        have any questions, you can send us an email to{" "}
        <span style={{ color: "#8089cb" }}>{SOCIETY_EMAIL[society]}</span>.
      </Typography>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={TransitionUp}
      open={open}
      message={message}
    >
      <Stack spacing={2} sx={{ mt: 10, maxWidth: 500 }}>
        <SnackbarContent sx={{ p: 3 }} message={message} action={action} />
      </Stack>
    </Snackbar>
  );
}
