import { Fab, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";

const BackToDashboardButton = () => {
  return (
    <Tooltip title="Back to App">
      <Fab
        color="primary"
        component={RouterLink}
        to="/app/general/conferences"
        size="medium"
        sx={{
          bottom: 0,
          margin: (theme) => theme.spacing(4),
          position: "fixed",
          right: 0,
          zIndex: (theme) => theme.zIndex.speedDial,
        }}
      >
        <DashboardIcon fontSize="small" />
      </Fab>
    </Tooltip>
  );
};

export default BackToDashboardButton;
