import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  invitations: [],
  bids: [],
};

const slice = createSlice({
  name: "bids",
  initialState,
  reducers: {
    getInvitations(state, action) {
      const { invitations } = action.payload;

      state.invitations = invitations;
    },

    setInvitations(state, action) {
      const { invitations } = action.payload;

      state.invitations = invitations;
    },

    getBids(state, action) {
      const { bids } = action.payload;

      state.bids = bids;
    },

    setBids(state, action) {
      const { bids } = action.payload;

      state.bids = bids;
    },
  },
});

export const getInvitations = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.BID_INVITATIONS);

    dispatch(slice.actions.getInvitations({ invitations: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const setInvitations = (invitations) => (dispatch) => {
  try {
    dispatch(slice.actions.setInvitations({ invitations }));
  } catch (error) {
    console.log(error);
  }
};

export const getBids = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.BIDS_ENDPOINT);

    dispatch(slice.actions.getBids({ bids: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const setBids = (bids) => (dispatch) => {
  try {
    dispatch(slice.actions.setBids({ bids }));
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
