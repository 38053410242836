import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedEvent: {},
};

const slice = createSlice({
  name: "selectedEvent",
  initialState,
  reducers: {
    setEvent(state, action) {
      const { event } = action.payload;
      state.selectedEvent = event;
    },
    resetSelectedEvent(state) {
      state.selectedEvent = initialState.selectedEvent;
    },
  },
});

export const setEvent = (event) => async (dispatch) => {
  dispatch(
    slice.actions.setEvent({
      event,
    })
  );
};

export const resetSelectedEvent = () => async (dispatch) => {
  dispatch(slice.actions.resetSelectedEvent());
};

export const { reducer } = slice;

export default slice;
