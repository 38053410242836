import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  app: undefined,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    logout(state, action) {
      const { app } = action.payload;

      state.app = app;
    },
  },
});

export const logout = () => async (dispatch) => {
  dispatch(slice.actions.logout({ app: undefined }));
};

export const { reducer } = slice;

export default slice;
