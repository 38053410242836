import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  reviews: [],
  invitations: [],
};

const slice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    getReviews(state, action) {
      const { reviews } = action.payload;

      state.reviews = reviews;
    },
    getInvitations(state, action) {
      const { invitations } = action.payload;

      state.invitations = invitations;
    },
  },
});

export const getReviews = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.REVIEWS_ENDPOINT);

    dispatch(
      slice.actions.getReviews({
        reviews: response.data,
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const getInvitations = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.REVIEW_INVITATIONS);

    dispatch(
      slice.actions.getInvitations({
        invitations: response.data,
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
