export const USER_TYPE = {
  REGISTERED: "registered",
  CACHED: "cached",
  NOT_REGISTERED: "not-registered",
};

export const USER_ROLES = {
  USER: "user",
  AUTHOR: "author",
  BIDDER: "bidder",
  REVIEWER: "reviewer",
  TRACKCHAIR: "trackchair",
  SSCC: "specialsessionchair",
  TPCHAIR: "technicalprogramchair",
  GCHAIR: "generalchair",
  VP_WORKSHOPS: "vp-workshops",
  VP_CONFERENCES: "vp-conferences",
  CONFERENCES_COMMITTEE: "conferences-committee",
  SYPA_CHAIR: "sypa-chair",
  ADMIN: "administrator",
};

export const TCS_REQUEST_STATUS = {
  IN_PROGRESS: "in_progress",
  NEED_INFORMATION: "need_information",
  ON_COMMITTEE: "on_committee",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const SPONSORED_STATUS = {
  IN_PROGRESS: "in_progress",
  NEED_INFORMATION: "need_information",
  ON_COMMITTEE: "on_committee",
  CLOSED: "closed",
  ADCOM_CONSIDERATION: "adcom_consideration",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const SYSTEM_ACTIVATION_STATUS = {
  IN_PROGRESS: "in_progress",
  NEED_INFORMATION: "need_information",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const MANUSCRIPTS_QUICK_FILTER = {
  PAID_NO_FINAL: "paid no final",
  PAID_NO_CRITE: "paid no crite",
  PAID_FIN_OK: "paid fin ok",
  PAID_NOT_ACC: "paid not acc",
  ACC_NOT_PAID: "acc not paid",
  ACCEPTED_DONE: "accepted done",
  ACC_NO_CRITE: "acc no crite",
  FINC_NOT_PAID: "finc not paid",
  ACCEPTED_FINC: "accepted finc",
  ACC_NO_FINAL: "acc no final",
};

// sites

export const IEEE_SITE = "https://www.ieee.org/";
export const IES_SITE = "http://www.ieee-ies.org/";
export const IES_CONTACT = "http://www.ieee-ies.org/home/contact";
export const IEEE_PRIVACY_POLICY = "https://www.ieee.org/security-privacy.html";
export const IEEE_ACCESSIBILITY =
  "http://www.ieee.org/accessibility_statement.html";
export const IEEE_NONDISCRIMINATION_POLICY =
  "http://www.ieee.org/about/corporate/governance/p9-26.html";
export const IEEE_SITEMAP = "https://www.ieee.org/sitemap.html";
export const IEEE_ETHICS_REPORTING =
  "https://secure.ethicspoint.com/domain/media/en/gui/20410/index.html";
export const IEEE_TERMS =
  "https://www.ieee.org/about/help/site-terms-conditions.html";

// forms

export const titleOptions = ["Prof.", "Dr.", "Mr.", "Mrs.", "Ms."];

// components

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 40, 60, 100];
export const ROWS_PER_PAGE_OPTIONS_SHORT = [2, 4, 6, 8];
export const ROWS_PER_PAGE_OPTIONS_LARGE = [10, 100, 250, 500, 1000];

// papers

export const PAPER_STATE = {
  DRAFT: "draft",
  INITIAL_SUBMITTED: "initial_submitted",
  UNDER_REVIEW: "under_review",
  INITIAL_APPROVED: "initial_approved",
  INITIAL_REJECTED: "initial_rejected",
  FINAL_SUBMITTED: "final_submitted",
  VALIDATING: "validating",
  FINAL_APPROVED: "final_approved",
  FINAL_REJECTED: "final_rejected",
  WITHDRAWN: "withdrawn",
};

export const PAPER_STATE_LABEL = {
  draft: "Draft",
  initial_submitted: "Initial submitted",
  under_review: "Under review",
  initial_approved: "Initial approved",
  initial_rejected: "Initial rejected",
  final_submitted: "Final submitted",
  validating: "Validating",
  final_approved: "Final approved",
  final_rejected: "Final rejected",
  withdrawn: "Withdrawn",
};

export const SUBMISSION_FILE_MAX_SIZE = 4; // in MB
export const CSV_FILE_MAX_SIZE = 1; // in MB

export const ZIP_FILE_MAX_SIZE = 10; // in MB

export const MAX_KEYWORDS_ALLOWED = 3;

// reviews

export const REVIEW_STATUS = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  WITHDRAWN: "withdrawn",
};

// bids

export const BID_ASSIGNMENT_STATUS = {
  PENDING: "pending",
  IN_PROCESS: "in_process",
  CONCLUDED: "concluded",
};

export const BID_STATUS = {
  INTERESTED: "interested",
  NEUTRAL: "neutral",
  NOT_INTERESTED: "not_interested",
  CONFLICT_OF_INTEREST: "conflict_of_interest",
};

export const TRACKS_CSV_HEADERS = [
  "abbreviation",
  "name",
  "isSpecialSession",
  "hasSpecificDeadlines",
  "submissionInitialDeadline",
  "biddingDeadline",
  "reviewingDeadline",
  "submissionFinalDeadline",
];

export const CSV_HEADER_KEYS = {
  ORGANIZERS: "organizers",
  REGISTERED: "registered",
  DECISIONS: "decisions",
  NOTIFICATIONS: "notifications",
  TRACK_CHAIRS: "trackchairs",
};

export const CSV_HEADERS = {
  organizers: ["firstName", "lastName", "email", "role"],
  registered: ["transaction"],
  decisions: ["transaction", "decision"],
  notifications: ["transaction"],
  trackchairs: ["track", "firstName", "lastName", "email"],
  approvals: ["transaction", "decision"],
  tracks: [
    "abbreviation",
    "name",
    "isSpecialSession",
    "hasSpecificDeadlines",
    "submissionInitialDeadline",
    "biddingDeadline",
    "reviewingDeadline",
    "submissionFinalDeadline",
  ],
};

// society form documents

export const ATTACHMENT_MAX_FILE_SIZE = 10000000;

export const ATTACHMENT_KEY = {
  CALL_FOR_PAPERS: "Call for papers",
  PRESENTATION: "Presentation of the event",
  BUDGET: "Preliminary budget",
  COMMITTEES: "Committees",
  OTHER: "Other relevant documents (optional)",
};

export const ATTACHMENT_NAME = {
  CALL_FOR_PAPERS: "callForPapers",
  PRESENTATION: "presentation",
  BUDGET: "preliminaryBudget",
  COMMITTEES: "committees",
  OTHER: "otherDocuments",
};

export const IES_TECHNICAL_COMMITTEES = [
  "1. Building Automation, Control, and Management",
  "2. Cloud and Wireless Systems for Industrial Applications",
  "3. Control, Robotics, and Mechatronics",
  "4. Data-Driven Control and Monitoring",
  "5. Education in Engineering and Industrial Technologies",
  "6. Electrical Machines",
  "7. Electronic Systems on Chip",
  "8. Energy Storage",
  "9. Factory Automation",
  "10. Human Factors",
  "11. Industrial Agents",
  "12. Industrial Cyber-Physical Systems",
  "13. Industrial Informatics",
  "14. MEMS and Nanotechnologies",
  "15. Motion Control",
  "16. Network-Based Control Systems and Applications",
  "17. Power Electronics",
  "18. Renewable Energy Systems",
  "19. Resilience and Security for Industrial Applications",
  "20. Sensors and Actuators",
  "21. Smart Grids",
  "22. Standards",
  "23. Transportation Electrification",
  "24. Technology Ethics and Society",
];

export const ALL_SERIES_ITEM = "ALL SERIES";

export const SYPA_STATUS = {
  IN_PROGRESS: "in_progress",
  NEED_INFORMATION: "need_information",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const SYPA_STATUS_LABEL = {
  in_progress: "In progress",
  need_information: "Need information",
  approved: "Approved",
  rejected: "Rejected",
};

export const SYPA_STATUS_COLOR = {
  in_progress: "primary",
  need_information: "corporate",
  approved: "success",
  rejected: "secondary",
};

export const DOCUMENT_TYPE = {
  INITIAL: "initial",
  FINAL: "final",
};
