import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Box,
  Link,
  Button,
  FormHelperText,
  TextField,
} from "@mui/material";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import useAuth from "../../../hooks/useAuth";
import useLoading from "../../../hooks/useLoading";
import * as endpoints from "../../../api/endpoints";
import { SOCIETY_EMAIL } from "../../../theme/societies";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string()
    .min(8, "At least 8 characteres")
    .max(255)
    .required("Password is required"),
});

const LoginForm = (props) => {
  const { login } = useAuth();
  const [userEmail, setUserEmail] = useState(null);
  const [showUserNotFoundError, setShowUserNotFoundError] = useState(false);
  const [showUserNotEnabledError, setShowUserNotEnabledError] = useState(false);
  const [showConfirmationResent, setShowConfirmationResent] = useState(false);
  const [showUnauthorized, setShowUnauthorized] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { showLoadingScreen, showSnackMessage } = useLoading();

  const resendConfirmation = async () => {
    try {
      showLoadingScreen(true);

      const response = await axios.post(endpoints.AUTH_RESEND_REGISTRATION, {
        email: userEmail,
      });

      if (
        response &&
        response.status &&
        (response.status === StatusCodes.OK ||
          response.status === StatusCodes.CREATED)
      ) {
        setShowConfirmationResent(true);
        setShowUserNotEnabledError(false);
        setShowUserNotFoundError(false);
        setShowUnauthorized(false);
      }

      showLoadingScreen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showSnackMessage("error", error.response.data.message);
      } else {
        showSnackMessage(
          "error",
          "An unknown server error occurred when attempting to log in"
        );
      }

      showLoadingScreen(false);
    }
  };

  const formikSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setUserEmail(values.email);

      await login(values.email, values.password);

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }

      setShowUserNotFoundError(false);
      setShowUserNotEnabledError(false);
      setShowConfirmationResent(false);
      setShowUnauthorized(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message === "User not enabled") {
          setShowUserNotEnabledError(true);
          setShowUserNotFoundError(false);
          setShowConfirmationResent(false);
          setShowUnauthorized(false);
        } else if (error.response.data.message === "User not found") {
          setShowUserNotFoundError(true);
          setShowUserNotEnabledError(false);
          setShowConfirmationResent(false);
          setShowUnauthorized(false);
        } else if (error.response.data.message === "Unauthorized") {
          setShowUserNotFoundError(false);
          setShowUserNotEnabledError(false);
          setShowConfirmationResent(false);
          setShowUnauthorized(true);
        } else {
          setShowUserNotFoundError(false);
          setShowUserNotEnabledError(false);
          setShowConfirmationResent(false);
          setShowUnauthorized(false);
          showSnackMessage(
            "error",
            "An unknown server error occurred when attempting to log in"
          );
        }
      } else {
        showSnackMessage(
          "error",
          "An unknown server error occurred when attempting to log in"
        );
      }

      if (isMountedRef.current) {
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={formikSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          {showConfirmationResent && (
            <Alert severity="success" sx={{ mb: 2 }}>
              An email confirmation message has been sent to your inbox.
            </Alert>
          )}

          {showUserNotFoundError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Email address not registered. Click on <b>'Create new account'</b>{" "}
              button to create your account.
            </Alert>
          )}

          {showUnauthorized && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Invalid password.
            </Alert>
          )}

          {showUserNotEnabledError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Email address not confirmed. Click{" "}
              <Link
                color="secondary"
                onClick={() => resendConfirmation()}
                style={{ cursor: "pointer" }}
              >
                <b>here</b>
              </Link>{" "}
              to receive a new confirmation email.
            </Alert>
          )}

          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />

          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            autoComplete="on"
            variant="outlined"
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Button
              color="secondary"
              fullWidth
              size="large"
              variant="contained"
              component={RouterLink}
              to="/authentication/register"
            >
              Create new account
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              <div>
                Contact{" "}
                <b>{SOCIETY_EMAIL[process.env.REACT_APP_IEEE_SOCIETY]}</b> if
                you have any problems.
              </div>
            </Alert>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
