import { createSlice } from "@reduxjs/toolkit";
import { statusOptions } from "../../utils/options";

const initialState = {
  manuscriptFilters: {
    status: [
      ...statusOptions.filter((o) => o.value !== "all").map((o) => o.value),
      "withdrawn",
    ],
    reviewersInvitedMin: undefined,
    reviewersInvitedMax: undefined,
    reviewersAgreedMin: undefined,
    reviewersAgreedMax: undefined,
    reviewsMin: undefined,
    reviewsMax: undefined,
    averageScoreMin: undefined,
    averageScoreMax: undefined,
    recommendation: undefined,
    decision: undefined,
    copyrighted: undefined,
    registered: undefined,
    hasOrganizerConflict: undefined,
    isScoreDiscordant: undefined,
    submittedFinal: undefined,
  },
};

const slice = createSlice({
  name: "manuscriptFilters",
  initialState,
  reducers: {
    updateFilters(state, action) {
      const { filters } = action.payload;

      state.manuscriptFilters = filters;
    },
    resetState(state) {
      state.manuscriptFilters = initialState.manuscriptFilters;
    },
  },
});

export const updateFilters = (filters) => async (dispatch) => {
  dispatch(slice.actions.updateFilters({ filters }));
};

export const resetState = () => async (dispatch) => {
  dispatch(slice.actions.resetState());
};

export const { reducer } = slice;

export default slice;
