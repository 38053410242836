import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { Formik } from "formik";
import {
  Box,
  Link,
  Button,
  FormHelperText,
  TextField,
  Alert,
} from "@mui/material";
import useLoading from "../../../hooks/useLoading";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import * as endpoints from "../../../api/endpoints";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const PasswordRecoveryForm = () => {
  const isMountedRef = useIsMountedRef();
  const [error, setError] = useState("");
  const { showLoadingScreen, showSnackMessage } = useLoading();
  const [success, setSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [showUserNotFoundError, setShowUserNotFoundError] = useState(false);
  const [showUserNotEnabledError, setShowUserNotEnabledError] = useState(false);
  const [showConfirmationResent, setShowConfirmationResent] = useState(false);

  const resendConfirmation = async () => {
    try {
      showLoadingScreen(true);

      const response = await axios.post(endpoints.AUTH_RESEND_REGISTRATION, {
        email: userEmail,
      });

      if (
        response &&
        response.status &&
        (response.status === StatusCodes.OK ||
          response.status === StatusCodes.CREATED)
      ) {
        setShowConfirmationResent(true);
        setShowUserNotEnabledError(false);
        setShowUserNotFoundError(false);
      }

      showLoadingScreen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showSnackMessage("error", error.response.data.message);
      } else {
        showSnackMessage(
          "error",
          "An unknown server error occurred when attempting to log in"
        );
      }

      showLoadingScreen(false);
    }
  };

  const formikSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setUserEmail(values.email);

      const response = await axios.post(
        endpoints.AUTH_FORGOT_PASSWORD_REQUEST,
        { email: values.email }
      );

      if (response.status === StatusCodes.CREATED) {
        setSuccess(true);
        setError("");
      } else {
        setSuccess(false);
        setError(
          "Forgot password request could not be sent. If the problem persists contact the administrator."
        );
      }

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }

      setShowUserNotFoundError(false);
      setShowUserNotEnabledError(false);
      setShowConfirmationResent(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message === "User not enabled") {
          setShowUserNotEnabledError(true);
          setShowUserNotFoundError(false);
          setShowConfirmationResent(false);
        }

        if (error.response.data.message === "User not found") {
          setShowUserNotFoundError(true);
          setShowUserNotEnabledError(false);
          setShowConfirmationResent(false);
        }
      } else {
        showSnackMessage(
          "error",
          "An unknown server error occurred when attempting to submit forgot password request"
        );
      }

      if (isMountedRef.current) {
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={formikSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {error && (
            <Box sx={{ my: 2 }}>
              <Alert severity="error">
                <div>{error}</div>
              </Alert>
            </Box>
          )}

          {success && (
            <Box sx={{ my: 2 }}>
              <Alert severity="success">
                <div>
                  Password recovery link has been sent to your email address.
                  Please, check your inbox.
                </div>
              </Alert>
            </Box>
          )}

          {showConfirmationResent && (
            <Alert severity="success" sx={{ mb: 2 }}>
              An email confirmation message has been sent to your inbox.
            </Alert>
          )}

          {showUserNotFoundError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Email address not registered. Go to the login form and click on{" "}
              <b>'Create new account'</b> button to create your account.
            </Alert>
          )}

          {showUserNotEnabledError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Email address not confirmed. Click{" "}
              <Link
                color="secondary"
                onClick={() => resendConfirmation()}
                style={{ cursor: "pointer" }}
              >
                <b>here</b>
              </Link>{" "}
              to receive a new confirmation email.
            </Alert>
          )}

          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 3 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Request password change
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PasswordRecoveryForm;
