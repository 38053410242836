import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import {
  DashboardNavbar,
  DashboardSidebar,
  Footer,
  InfoBanner,
} from "./components";
import DashboardFallback from "./components/dashboard-fallback";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  // overflow: "hidden",
  overflowX: "clip",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  // overflow: "hidden",
  overflowX: "clip",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 275,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  // overflow: "hidden",
  overflowX: "clip",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  // overflow: "auto",
  overflowX: "clip",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  const handleSelectItem = () => {
    if (isSidebarMobileOpen) {
      setIsSidebarMobileOpen(false);
    }
  };

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)}
      />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        handleSelectItem={handleSelectItem}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent id="layout-content">
            <ErrorBoundary FallbackComponent={DashboardFallback}>
              <InfoBanner />
              <Outlet />
              <Footer />
            </ErrorBoundary>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
