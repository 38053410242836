import merge from "lodash/merge";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { IES_THEME_OPTIONS } from "./ies-theme";
import { EDS_THEME_OPTIONS } from "./eds-theme";
import { SOCIETIES } from "./societies";
import { baseOptions } from "./base-theme";

const themesOptions = {
  [SOCIETIES.IES]: {
    ...IES_THEME_OPTIONS,
  },
  [SOCIETIES.EDS]: {
    ...EDS_THEME_OPTIONS,
  },
};

export const createMuiTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[SOCIETIES.IES];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
