import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import {
  DocumentationNavbar,
  DocumentationSidebar,
  BackToDashboardButton,
} from "../modules/documentation/components";

const DocumentationLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DocumentationLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "275px",
  },
}));

const DocumentationLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DocumentationLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DocumentationLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  const handleSelectItem = () => {
    if (isSidebarMobileOpen) {
      setIsSidebarMobileOpen(false);
    }
  };

  return (
    <DocumentationLayoutRoot>
      <DocumentationNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)}
      />
      <DocumentationSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        handleSelectItem={handleSelectItem}
        openMobile={isSidebarMobileOpen}
      />
      <DocumentationLayoutWrapper>
        <DocumentationLayoutContainer>
          <DocumentationLayoutContent>
            <Outlet />
            <BackToDashboardButton />
          </DocumentationLayoutContent>
        </DocumentationLayoutContainer>
      </DocumentationLayoutWrapper>
    </DocumentationLayoutRoot>
  );
};

export default DocumentationLayout;
