import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ReviewDoneDetailRedirect = () => {
  const { reviewId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/app/reviews/done/${reviewId}`);
  }, [navigate, reviewId]);

  return null;
};

export default ReviewDoneDetailRedirect;
