import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Backdrop, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";

const LoadingContext = createContext();

export const LoadingProvider = (props) => {
  const { children } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [active, setActive] = useState(false);

  const showLoadingScreen = (show) => {
    setActive(show);
  };

  const action = (key) => (
    <IconButton
      sx={{ color: "#ffffff" }}
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const showSnackMessage = (type, message) => {
    switch (type) {
      case "error":
        enqueueSnackbar(message, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          variant: "error",
          persist: true,
          action,
        });
        break;

      case "info":
        enqueueSnackbar(message, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          variant: "success",
          autoHideDuration: 3000,
        });
        break;

      case "success":
        enqueueSnackbar(message, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          variant: "success",
          autoHideDuration: 5000,
        });
        break;

      case "warning":
        enqueueSnackbar(message, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          variant: "warning",
          autoHideDuration: 7000,
        });
        break;

      default:
        break;
    }
  };

  return (
    <LoadingContext.Provider
      value={{
        showLoadingScreen,
        showSnackMessage,
      }}
    >
      {children}
      <Backdrop sx={{ zIndex: 2000, color: "#fff" }} open={active}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoadingContext;
