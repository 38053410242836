import axios from "axios";

export const getTracks = async () => {
  const { data } = await axios.get("/tracks");
  return data;
};

export const getTracksStatsInvitations = async () => {
  const { data } = await axios.get("/tracks/stats/invitations");
  return data;
};

export const getTracksStatsPaperInvitations = async () => {
  const { data } = await axios.get("/tracks/stats/paper-invitations");
  return data;
};

export const getTracksStatsPaperStatus = async () => {
  const { data } = await axios.get("/tracks/stats/paper-status");
  return data;
};

export const getTracksStatsPaperReviews = async () => {
  const { data } = await axios.get("/tracks/stats/paper-reviews");
  return data;
};

export const getTracksStatsPaperConflicts = async () => {
  const { data } = await axios.get("/tracks/stats/paper-conflicts");
  return data;
};

export const getTracksStatsPaperRecommendations = async () => {
  const { data } = await axios.get("/tracks/stats/paper-recommendations");
  return data;
};
