import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ChangePasswordRedirection = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/authentication/change-password/${token}`);
  }, [token, navigate]);

  return null;
};

export default ChangePasswordRedirection;
