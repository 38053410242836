import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Navigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import PropTypes from "prop-types";
import useAuth from "../../../hooks/useAuth";
import Login from "../pages/login";

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const { logout } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axios.get("/auth/me");
      } catch (error) {
        // not authenticated
        await logout();
        toast(
          <Typography variant="subtitle2">
            Your session has expired!
          </Typography>,
          {
            icon: <WarningIcon color="corporate" />,
          }
        );
      }
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
