import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  events: [],
};

const slice = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;

      state.events = events;
    },
  },
});

export const getEvents = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.EVENTS);

    dispatch(slice.actions.getEvents({ events: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
