import PropTypes from "prop-types";
import { SOCIETIES } from "../theme/societies";

const SocietyLogo = ({ variant }) => {
  const society = process.env.REACT_APP_IEEE_SOCIETY;

  const alt = `${society} Logo`;
  const src = `/static/society/${society}/logos/logo-${variant}.png`;

  const getSizeStyles = () => {
    switch (society) {
      case SOCIETIES.IES:
        return { width: "155px", height: "53px" };
      case SOCIETIES.EDS:
        return { width: "120px", height: "60px" };
      default:
        return {};
    }
  };

  return (
    <img
      alt={alt}
      src={src}
      style={{ ...getSizeStyles(), objectFit: "contain" }}
    />
  );
};

SocietyLogo.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default SocietyLogo;
