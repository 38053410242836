import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ReviewAskedDetailRedirect = () => {
  const { transaction } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/app/reviews/asked/${transaction}`);
  }, [navigate, transaction]);

  return null;
};

export default ReviewAskedDetailRedirect;
