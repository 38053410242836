import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { Box, Button, FormHelperText, TextField, Alert } from "@mui/material";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import * as endpoints from "../../../api/endpoints";

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, "At least 8 characteres")
    .max(50)
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const ChangePasswordForm = () => {
  const isMountedRef = useIsMountedRef();
  const { token } = useParams();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const formikSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const tokenValidationResponse = await axios.get(
        endpoints.AUTH_FORGOT_PASSWORD_RESET + "/" + token
      );

      if (tokenValidationResponse.status === StatusCodes.OK) {
        const response = await axios.post(
          endpoints.AUTH_FORGOT_PASSWORD_RESET,
          { newPassword: values.confirmPassword, token }
        );

        if (response.status === StatusCodes.NO_CONTENT) {
          setSuccess(true);
          setError("");
        } else {
          setSuccess(false);
          setError(
            "Change password action could not be sent. Try again or contact with the administrator."
          );
        }

        if (isMountedRef.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      }
    } catch (err) {
      setError(
        "The token for password change is invalid or has expired (10 minutes). Please request password again."
      );

      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={formikSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {error && (
            <Box sx={{ my: 2 }}>
              <Alert severity="error">
                <div>{error}</div>
              </Alert>
            </Box>
          )}

          {success && (
            <Box sx={{ my: 2 }}>
              <Alert severity="success">
                <div>
                  Your password has been changed successfully. Login to use the
                  platform..
                </div>
              </Alert>
            </Box>
          )}

          {!error && !success && (
            <div>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />

              <TextField
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label="Confirm password"
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />
            </div>
          )}

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          {!error && !success && (
            <Box sx={{ mt: 3 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Request password change
              </Button>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
