import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manuscripts: [],
};

const slice = createSlice({
  name: "openManuscripts",
  initialState,
  reducers: {
    addManuscript(state, action) {
      const { manuscript } = action.payload;
      state.manuscripts = [...state.manuscripts, manuscript];
    },
    addManuscripts(state, action) {
      const { manuscripts } = action.payload;
      state.manuscripts = [...state.manuscripts, ...manuscripts];
    },
    popManuscript(state, action) {
      const { manuscript } = action.payload;
      state.manuscripts = state.manuscripts.filter(
        (m) => m.id !== manuscript.id
      );
    },
    removeAllManuscripts(state) {
      state.manuscripts = [];
    },
  },
});

export const addManuscript = (manuscript) => async (dispatch) => {
  dispatch(slice.actions.addManuscript({ manuscript }));
};

export const addManuscripts = (manuscripts) => async (dispatch) => {
  dispatch(slice.actions.addManuscripts({ manuscripts }));
};

export const popManuscript = (manuscript) => async (dispatch) => {
  dispatch(slice.actions.popManuscript({ manuscript }));
};

export const removeAllManuscripts = () => async (dispatch) => {
  dispatch(slice.actions.removeAllManuscripts());
};

export const { reducer } = slice;

export default slice;
