import { createSlice } from "@reduxjs/toolkit";
import * as constants from "../../constants";

const initialState = {
  manuscriptsPagination: {
    page: 0,
    limit: Math.min(...constants.ROWS_PER_PAGE_OPTIONS),
  },
};

const slice = createSlice({
  name: "manuscriptsPagination",
  initialState,
  reducers: {
    changePage(state, action) {
      const { page } = action.payload;

      state.manuscriptsPagination.page = page;
    },
    changeLimit(state, action) {
      const { limit } = action.payload;

      state.manuscriptsPagination.limit = limit;
    },
    resetPagination(state) {
      state.manuscriptsPagination = initialState.manuscriptsPagination;
    },
  },
});

export const changePage = (page) => async (dispatch) => {
  dispatch(slice.actions.changePage({ page }));
};

export const changeLimit = (limit) => async (dispatch) => {
  dispatch(slice.actions.changeLimit({ limit }));
};

export const resetPagination = () => async (dispatch) => {
  dispatch(slice.actions.resetPagination());
};

export const { reducer } = slice;

export default slice;
