import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../redux/store";
import { setEvent, resetSelectedEvent } from "../../redux/slices/selectedEvent";
import { Divider, Menu, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTracks } from "../../hooks/tracks";
import { useToast } from "../../hooks/useToast";
import { CalendarMonthOutlined, KeyboardArrowDown } from "@mui/icons-material";
import { removeAllManuscripts } from "../../redux/slices/openManuscripts";
import { resetPagination } from "../../redux/slices/manuscripts-pagination";

const EventsMenu = () => {
  const dispatch = useDispatch();
  const { triggerError } = useToast();
  const [events, setEvents] = useState([]);
  const { data: tracks, error, isLoading } = useTracks();
  const [eventAnchorEl, setEventAnchorEl] = useState(null);
  const { selectedEvent } = useSelector((state) => state.selectedEvent);

  if (error) {
    triggerError(error, "Error while fetching tracks");
  }

  useEffect(() => {
    if (tracks?.length > 0) {
      const eventsData = tracks.map((track) => track.event);
      const eventIds = eventsData.map((event) => event.id);
      const uniqueEventIds = Array.from(new Set(eventIds));

      const uniqueEvents = uniqueEventIds.map((id) => {
        return eventsData.find((data) => data.id === id);
      });

      setEvents(uniqueEvents);
    }
  }, [tracks]);

  const handleEventSortOpen = (event) => {
    setEventAnchorEl(event.currentTarget);
  };

  const handleEventSortClose = () => {
    setEventAnchorEl(null);
  };

  const handleEventSortSelect = (value) => {
    dispatch(setEvent(value));
    dispatch(removeAllManuscripts());
    dispatch(resetPagination());
    setEventAnchorEl(null);
  };

  const handleResetEvent = () => {
    dispatch(resetSelectedEvent());
    dispatch(resetPagination());
    setEventAnchorEl(null);
  };

  return (
    <>
      <LoadingButton
        color="light"
        aria-controls="events-menu"
        aria-haspopup="true"
        startIcon={<CalendarMonthOutlined fontSize="small" />}
        endIcon={<KeyboardArrowDown fontSize="small" />}
        variant="text"
        onClick={handleEventSortOpen}
        loading={isLoading}
        loadingPosition="start"
      >
        {selectedEvent.code
          ? selectedEvent.code
          : `My conferences (${events.length})`}
      </LoadingButton>
      <Menu
        id="event-menu"
        anchorEl={eventAnchorEl}
        keepMounted
        open={Boolean(eventAnchorEl)}
        onClose={handleEventSortClose}
      >
        <MenuItem
          key="default-conference"
          sx={{ minWidth: 150 }}
          onClick={() => handleResetEvent()}
        >
          {`My conferences (${events.length})`}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {events.map((event) => (
          <MenuItem
            key={event.id}
            sx={{ minWidth: 150 }}
            onClick={() => handleEventSortSelect(event)}
          >
            {event.code}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EventsMenu;
