import { Grid, Box, Typography, Divider, Link, Paper } from "@mui/material";
import * as constants from "../../constants";
import SocialMediaButton from "../../components/social-media-button";
import { SOCIAL_MEDIA, SOCIETY_EMAIL } from "../../theme/societies";
import { format } from "date-fns";

const currentYear = format(new Date(), "yyyy");

const copyrightText = `© Copyright ${currentYear} IEEE - All rights reserved. A not-for-profit organization, IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity.`;

const societyCopyrightText = `${currentYear} ${process.env.REACT_APP_IEEE_SOCIETY.toUpperCase()} Conferences Community`;

const Footer = () => {
  const society = process.env.REACT_APP_IEEE_SOCIETY;

  return (
    <Paper sx={{ borderRadius: "inherit" }}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box sx={{ p: 2 }}>
            <Typography variant="caption" gutterBottom>
              <Link href="/authentication/login">Home</Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IEEE_SITEMAP}
                target="_blank"
                rel="noopener noreferrer"
              >
                IEEE Sitemap
              </Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IES_CONTACT}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Society
              </Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IEEE_ACCESSIBILITY}
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility
              </Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IEEE_NONDISCRIMINATION_POLICY}
                target="_blank"
                rel="noopener noreferrer"
              >
                Nondiscrimination Policy
              </Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IEEE_ETHICS_REPORTING}
                target="_blank"
                rel="noopener noreferrer"
              >
                IEEE Ethics Reporting
              </Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IEEE_PRIVACY_POLICY}
                target="_blank"
                rel="noopener noreferrer"
              >
                IEEE Privacy Policy
              </Link>
              <Link
                sx={{ ml: 2 }}
                href={constants.IEEE_TERMS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Link>
            </Typography>

            <br />

            <Typography color="textSecondary" variant="caption">
              {societyCopyrightText}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center", p: 1 }}>
            <SocialMediaButton
              color="primary"
              socialMedia={SOCIAL_MEDIA.FACEBOOK}
            />
            <SocialMediaButton
              color="corporate"
              socialMedia={SOCIAL_MEDIA.INSTAGRAM}
            />
            <SocialMediaButton
              color="primary"
              socialMedia={SOCIAL_MEDIA.TWITTER}
            />
            <SocialMediaButton
              color="primary"
              socialMedia={SOCIAL_MEDIA.LINKEDIN}
            />
            <SocialMediaButton
              color="secondary"
              socialMedia={SOCIAL_MEDIA.YOUTUBE}
            />

            <br />

            <Typography color="textSecondary" variant="caption">
              Need help? <b>{SOCIETY_EMAIL[society]}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center" }}
          color="textSecondary"
          variant="caption"
        >
          {copyrightText}
        </Typography>
      </Box>
    </Paper>
  );
};

export default Footer;
