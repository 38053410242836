import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import useScrollReset from "./hooks/useScrollReset";
import GlobalStyles from "./components/global-styles";
import { createMuiTheme } from "./theme";
import routes from "./routes";
import { LoadingProvider } from "./contexts/loading-context";
import useAuth from "./hooks/useAuth";
import SplashScreen from "./components/splash-screen";

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollReset();

  const theme = createMuiTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: false,
    theme: process.env.REACT_APP_IEEE_SOCIETY,
  });

  return (
    <ThemeProvider theme={theme}>
      <Toaster position="top-center" />
      <SnackbarProvider dense={false} maxSnack={3}>
        <LoadingProvider>
          <GlobalStyles />
          {auth.isInitialized ? content : <SplashScreen />}
        </LoadingProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
