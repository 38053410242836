import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  conferences: [],
};

const slice = createSlice({
  name: "conferences",
  initialState,
  reducers: {
    getConferences(state, action) {
      const { conferences } = action.payload;

      state.conferences = conferences;
    },
  },
});

export const getConferences = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.EVENTS_MANAGEMENT);

    dispatch(slice.actions.getConferences({ conferences: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
