import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "../../hooks/useAuth";
import EventsMenu from "./events-menu";
import TracksMenu from "./tracks-menu";
import AccountPopover from "./account-popover";
import SocietyLogo from "../../components/society-logo";
import { LOGO_VARIANTS } from "../../theme/societies";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { user } = useAuth();
  const { onSidebarMobileOpen, ...other } = props;

  const isChair = user.roles.some((role) =>
    [
      "trackchair",
      "specialsessionchair",
      "technicalprogramchair",
      "generalchair",
      "administrator",
    ].includes(role)
  );

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RouterLink to="/home/welcome">
              <SocietyLogo variant={LOGO_VARIANTS.WHITE} />
            </RouterLink>
            <Typography sx={{ ml: 2, fontFamily: "sans-serif" }} variant="h6">
              Conferences Community
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {isChair && (
          <Box sx={{ ml: 3 }}>
            <EventsMenu />
          </Box>
        )}
        {isChair && (
          <Box sx={{ ml: 3 }}>
            <TracksMenu />
          </Box>
        )}
        <Box sx={{ ml: 5 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
