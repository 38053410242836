import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  submissions: [],
  searchedSubmissions: [],
};

const slice = createSlice({
  name: "submissions",
  initialState,
  reducers: {
    getSubmissions(state, action) {
      const { submissions } = action.payload;

      state.submissions = submissions;
    },
    getSearchedSubmissions(state, action) {
      const { searchedSubmissions } = action.payload;

      state.searchedSubmissions = searchedSubmissions;
    },
  },
});

export const getSubmissions = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.SUBMISSIONS_ENDPOINT);

    dispatch(slice.actions.getSubmissions({ submissions: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const getSearchedSubmissions = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.SUBMISSIONS_SEARCH_ENDPOINT);

    dispatch(
      slice.actions.getSearchedSubmissions({
        searchedSubmissions: response.data,
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
