import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterItems: [],
};

const slice = createSlice({
  name: "filterItems",
  initialState,
  reducers: {
    setFilterItems(state, action) {
      const { filterItems } = action.payload;
      state.filterItems = filterItems;
    },
  },
});

export const setFilterItems = (filterItems) => async (dispatch) => {
  dispatch(
    slice.actions.setFilterItems({
      filterItems,
    })
  );
};

export const { reducer } = slice;

export default slice;
