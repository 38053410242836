import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTrack: {},
};

const slice = createSlice({
  name: "selectedTrack",
  initialState,
  reducers: {
    setTrack(state, action) {
      const { track } = action.payload;
      state.selectedTrack = track;
    },
    resetSelectedTrack(state) {
      state.selectedTrack = initialState.selectedTrack;
    },
  },
});

export const setTrack = (track) => async (dispatch) => {
  dispatch(
    slice.actions.setTrack({
      track,
    })
  );
};

export const resetSelectedTrack = () => async (dispatch) => {
  dispatch(slice.actions.resetSelectedTrack());
};

export const { reducer } = slice;

export default slice;
