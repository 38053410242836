import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import Scrollbar from "../../../components/scrollbar";
import * as constants from "../../../constants";
import { NavSection } from "../../../layouts/components";
import SocietyLogo from "../../../components/society-logo";
import { LOGO_VARIANTS } from "../../../theme/societies";

const sections = [
  {
    title: "Overview",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Welcome",
        path: "/app/docs/welcome",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Roles Hierarchy",
        path: "/app/docs/roles-hierarchy",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Video tutorials",
        path: "/app/docs/tutorials",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Account",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Account",
        path: "/app/docs/account",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Forgot Password",
        path: "/app/docs/forgot-password",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Authors",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Submit Manuscript",
        path: "/app/docs/submit-paper",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Submit Final Version",
        path: "/app/docs/submit-final",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Sign IEEE Copyright",
        path: "/app/docs/sign-copyright",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Manuscript Detail",
        path: "/app/docs/paper-detail",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Edit Manuscript",
        path: "/app/docs/edit-paper",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Manuscript Feedbacks",
        path: "/app/docs/paper-feedbacks",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Withdraw Manuscript",
        path: "/app/docs/withdraw-paper",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Reviewers",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Review Invitation",
        path: "/app/docs/review-invitation",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Review Assignment",
        path: "/app/docs/review-assignment",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Submit Review",
        path: "/app/docs/submit-review",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Reviews Done",
        path: "/app/docs/reviews-done",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Track Chairs",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Dashboard Overview",
        path: "/app/docs/dashboard-overview",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Manage Tracks",
        path: "/app/docs/manage-tracks",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Manage Submissions",
        path: "/app/docs/manage-submissions",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Invite Reviewers",
        path: "/app/docs/invite-reviewers",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Invite Bidders",
        path: "/app/docs/invite-bidders",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Manage Invitations",
        path: "/app/docs/manage-invitations",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Bids Decision",
        path: "/app/docs/bids-decision",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Recommendations",
        path: "/app/docs/recommendations",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Technical Program Chairs",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Manuscripts decision",
        path: "/app/docs/papers-decision",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Move manuscript to another track",
        path: "/app/docs/change-track",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "General Chairs",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Manage Conference",
        path: "/app/docs/manage-conference",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Support",
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Contact Support",
        path: "/app/docs/support",
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "FAQ",
        path: "/app/docs/faq",
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
];

const DocumentationSidebar = (props) => {
  const { onMobileClose, openMobile, handleSelectItem } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (!openMobile) {
      onMobileClose();
    }
  }, [onMobileClose, openMobile]);

  const isVisible = (allowedRoles) => {
    let allowedRolesCount = 0;

    allowedRoles.forEach((allowedRole) => {
      if (!user || user.roles.includes(allowedRole)) {
        allowedRolesCount++;
      }
    });

    return allowedRolesCount > 0;
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ mt: 8, display: { lg: "none", xs: "block" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/home/welcome">
              <SocietyLogo variant={LOGO_VARIANTS.DEFAULT} />
            </RouterLink>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          {sections
            .filter((section) => isVisible(section.roles))
            .map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                handleSelectItem={handleSelectItem}
                sx={{
                  "& + &": {
                    mt: 3,
                  },
                }}
                {...section}
              />
            ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        sx={{ display: { lg: "none", xs: "block" } }}
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: 275,
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        sx={{ display: { xs: "none", lg: "block" } }}
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 275,
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

DocumentationSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DocumentationSidebar;
