import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  Link,
  FormHelperText,
  TextField,
  Alert,
} from "@mui/material";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import * as constants from "../../../constants";
import countries from "../../../utils/countries";
import useAuth from "../../../hooks/useAuth";

const schema = Yup.object().shape({
  title: Yup.mixed()
    .oneOf(constants.titleOptions)
    .required("Title is required"),
  firstName: Yup.string().max(255).required("First name is required"),
  lastName: Yup.string().max(255).required("Last name is required"),
  affiliation: Yup.string().max(255).required("Affiliation is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  affiliationCountry: Yup.mixed()
    .oneOf(countries)
    .required("Country is required"),
  password: Yup.string().min(8).max(255).required("Password is required"),
  repeatPassword: Yup.string()
    .min(8)
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
  policy: Yup.boolean().oneOf([true], "This field must be checked"),
});

const RegisterForm = (props) => {
  const isMountedRef = useIsMountedRef();
  const { register } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const formikSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const error = await register(values);

      if (error) {
        setError(error);
        setSuccess(false);
      } else {
        setSuccess(true);
        setError("");
      }

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (error) {
      if (isMountedRef.current) {
        setStatus({ success: false });
        setSubmitting(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrors({ submit: error.response.data.message });
        } else {
          setErrors({
            submit:
              "An unknown server error occurred when attempting to create account",
          });
        }
      }
    }
  };

  return (
    <Formik
      initialValues={{
        title: "",
        firstName: "",
        lastName: "",
        affiliation: "",
        affiliationCountry: "",
        email: "",
        password: "",
        repeatPassword: "",
        policy: false,
        submit: null,
      }}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={formikSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          {error && (
            <Box sx={{ my: 2 }}>
              <Alert severity="error">
                <div>{error}</div>
              </Alert>
            </Box>
          )}

          {success && (
            <Box sx={{ my: 2 }}>
              <Alert severity="success">
                <div>
                  You have successfully registered. A confirmation email will be
                  sent to your email.
                </div>
              </Alert>
            </Box>
          )}

          <Grid container spacing={1}>
            <Grid container item md={6}>
              <TextField
                fullWidth
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                variant="outlined"
                name="title"
                select
                label="Title"
                margin="normal"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
              >
                {constants.titleOptions.map((title, idx) => (
                  <MenuItem key={idx} value={title}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container item md={6}>
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label="First name"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid container item md={6}>
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label="Last name"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid container item md={6}>
              <TextField
                error={Boolean(touched.affiliation && errors.affiliation)}
                fullWidth
                helperText={touched.affiliation && errors.affiliation}
                label="Affiliation"
                margin="normal"
                name="affiliation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.affiliation}
                variant="outlined"
              />
            </Grid>
            <Grid container item md={6}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid container item md={6}>
              <TextField
                fullWidth
                error={Boolean(
                  touched.affiliationCountry && errors.affiliationCountry
                )}
                helperText={
                  touched.affiliationCountry && errors.affiliationCountry
                }
                variant="outlined"
                name="affiliationCountry"
                select
                label="Country"
                margin="normal"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.affiliationCountry}
              >
                {countries.map((title, idx) => (
                  <MenuItem key={idx} value={title}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container item md={6}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                autoComplete="on"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid container item md={6}>
              <TextField
                error={Boolean(touched.repeatPassword && errors.repeatPassword)}
                fullWidth
                helperText={touched.repeatPassword && errors.repeatPassword}
                label="Repeat password"
                margin="normal"
                name="repeatPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                autoComplete="on"
                value={values.repeatPassword}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              ml: -1,
              mt: 2,
            }}
          >
            <Checkbox
              checked={values.policy}
              color="primary"
              name="policy"
              onChange={handleChange}
            />
            <Typography color="textSecondary" variant="body2">
              I have read the{" "}
              <Link
                color="primary"
                component="a"
                href={constants.IEEE_TERMS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Box>

          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>{errors.policy}</FormHelperText>
          )}

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Register
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;
