/** AUTHENTICATION API ENDPOINTS */

export const AUTH_LOGIN = "/auth/login";
export const AUTH_REGISTER = "/auth/register";
export const AUTH_LOG_OUT = "/auth/logout";
export const AUTH_VERIFY_ACCOUNT = "/auth/verify";
export const AUTH_IS_AUTHENTICATED = "/auth/me";
export const AUTH_FORGOT_PASSWORD_REQUEST = "/auth/forgotten-password";
export const AUTH_FORGOT_PASSWORD_RESET = "/auth/password-reset";
export const AUTH_RESEND_REGISTRATION = "/auth/resend-confirmation";
export const AUTH_UNSUSCRIBE = "/auth/quit";
export const AUTH_CHANGE_PASSWORD = "/auth/change-password";

/** UNREGISTERED REVIEW REJECT */
export const REVIEW_ASSIGNMENT_TOKEN = "/reviews/assignments/token";

/** USERS */

export const USERS = "/users";
export const USERS_ORCID = "/users/validate-orcid";

/** CONFERENCES API ENDPOINTS */

export const EVENTS = "/conferences";
export const EVENTS_MANAGEMENT = "/conferences/management";

/** TRACKS API ENDPOINTS */

export const TRACKS = "/tracks";
export const TRACKS_STATS = "/tracks/stats";

/** PAPERS API ENDPOINTS */

export const SUBMISSIONS_ENDPOINT = "/papers";
export const SUBMISSIONS_SEARCH_ENDPOINT = "/papers/search";
export const DOCUMENTS = "/documents";

/** REVIEWS API ENDPOINTS */

export const REVIEWS_ENDPOINT = "/reviews";
export const BIDS_ENDPOINT = "/bids";
export const ASSIGNMENTS_ENDPOINT = "/reviews/assignments";
export const INVITE_BIDDERS = "/bids/assignments";
export const REVIEW_INVITATIONS = "/reviews/assignments/trackchair";
export const BID_INVITATIONS = "/bids/assignments/trackchair";
export const BIDS_CONFIRM = "/bids/confirm";

/** TRACK CHAIR API ENDPOINTS */

export const SUBMISSIONS_DECISIONS_STATS = "/tracks/acceptance/stats";
export const BIDS_DECISIONS_STATS = "/tracks/bidding/stats";
export const WITHDRAW_ASSIGNMENT = "/reviews/assignments/withdraw";
export const REMIND_ASSIGNMENT = "/reviews/assignments/remind";
export const REMIND_BID_ASSIGNMENTS = "/bids/assignments/remind";
export const NOTIFY_ACCEPTANCE = "/papers/initial/notify";
export const REGISTER_PAPER = "/papers/registration";
export const ACCEPT_PAPERS = "/papers/initial/accept";
export const UNDO_DECISION = "/papers/initial/pending";
export const REJECT_PAPERS = "/papers/initial/reject";
export const CHANGE_TRACK = "/papers/change-track";

/** STATS ENDPOINTS */
export const TRACKS_INVITATIONS_STATS = "/tracks/stats/invitations";
export const PAPERS_STATUS_STATS = "/tracks/stats/paper-status";
export const CONFLICTS_STATS = "/tracks/stats/paper-conflicts";
export const PAPERS_INVITATIONS_STATS = "/tracks/stats/paper-invitations";
export const REVIEWS_STATS = "/tracks/stats/paper-reviews";
export const RECOMMENDATIONS_STATS = "/tracks/stats/paper-recommendations";

/** SOCIETY ENDPOINTS */
export const TCS_REQUEST = "/management/technical-cosponsorship-requests";
export const TCS_REQUEST_PROCESS =
  "/management/technical-cosponsorship-requests/:id/process";
export const FILES = "/files";

export const CONFERENCES_REQUESTS = "/conferences/requests";
export const CONFERENCE_REQUESTS_APPROVE = "/conferences/requests/:id/approve";
export const CONFERENCE_REQUESTS_REJECT = "/conferences/requests/:id/reject";

export const SPONSORSHIP_REQUESTS =
  "/management/sponsored-conference-application";
export const SPONSORSHIP_REQUESTS_PROCESS =
  "/management/sponsored-conference-application/:id/process";
export const OPERATING_AGREEMENT_REQUESTS = "/management/operating-agreements";
export const SYSTEM_ACTIVATION_APP_PROCESS =
  "/conferences/requests/:id/process";
export const OPERATIN_AGREEMENT_WITHDRAW =
  "/management/operating-agreements/:id/withdraw";

/** SERIES ENDPOINTS */

export const CONFERENCES_SERIES = "/conferences/series";

/** ERRORS */

export const FAILURE_ON_LOAD_CONFERENCES =
  "An unknown server error occurred when attempting to load conferences";

export const FAILURE_ON_SUBMIT_PAPER =
  "An unknown server error occurred when attempting to submit the manuscript";

export const FAILURE_ON_UPDATE_PROFILE =
  "An unknown server error occurred when attempting to update your profile";

export const FAILURE_ON_CREATE_CONFERENCE =
  "An unknown server error occurred when attempting to create the conference";

export const FAILURE_ON_CREATE_TRACK =
  "An unknown server error occurred when attempting to create the track";
