import PropTypes from "prop-types";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SOCIAL_MEDIA, SOCIAL_MEDIA_LINKS } from "../theme/societies";

const SocialMediaButton = ({ socialMedia, color }) => {
  const society = process.env.REACT_APP_IEEE_SOCIETY;

  const socialMediaLinks = SOCIAL_MEDIA_LINKS[society] || {};

  const getSocialIcon = () => {
    switch (socialMedia) {
      case SOCIAL_MEDIA.FACEBOOK:
        return <Facebook />;
      case SOCIAL_MEDIA.INSTAGRAM:
        return <Instagram />;
      case SOCIAL_MEDIA.LINKEDIN:
        return <LinkedIn />;
      case SOCIAL_MEDIA.TWITTER:
        return <Twitter />;
      case SOCIAL_MEDIA.YOUTUBE:
        return <YouTube />;
      default:
        return null;
    }
  };

  return (
    <IconButton
      color={color}
      component="a"
      href={socialMediaLinks[socialMedia]}
      target="_blank"
      rel="noopener noreferrer"
    >
      {getSocialIcon()}
    </IconButton>
  );
};

SocialMediaButton.propTypes = {
  socialMedia: PropTypes.oneOf([...Object.keys(SOCIAL_MEDIA)]).isRequired,
  color: PropTypes.string.isRequired,
};

export default SocialMediaButton;
