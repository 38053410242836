import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visibleColumns: [
    "transaction",
    "title",
    "similarity",
    "track",
    "status",
    "recommendation",
    "decision",
    "asked",
    "pending",
    "declined",
    "agree",
    "done",
    "score",
  ],
};

const slice = createSlice({
  name: "visibleColumns",
  initialState,
  reducers: {
    setVisibleColumns(state, action) {
      const { columns } = action.payload;

      state.visibleColumns = columns;
    },
    resetState(state) {
      state.visibleColumns = initialState.visibleColumns;
    },
  },
});

export const setVisibleColumns = (columns) => async (dispatch) => {
  dispatch(slice.actions.setVisibleColumns({ columns }));
};

export const resetState = () => async (dispatch) => {
  dispatch(slice.actions.resetState());
};

export const { reducer } = slice;

export default slice;
