export const statusOptions = [
  { label: "All", value: "all" },
  {
    label: "Initial submitted",
    value: "initial_submitted",
  },
  {
    label: "Initial approved",
    value: "initial_approved",
  },
  {
    label: "Initial rejected",
    value: "initial_rejected",
  },
  {
    label: "Under review",
    value: "under_review",
  },
  {
    label: "Final submitted",
    value: "final_submitted",
  },
];

export const roleOptions = [
  { label: "Author", value: "author" },
  { label: "Reviewer", value: "reviewer" },
  { label: "Track Chair", value: "track-chair" },
  { label: "Special Session Chair", value: "special-session-chair" },
  { label: "Technical Program Chair", value: "technical-program-chair" },
  { label: "General Chair", value: "general-chair" },
];

export const recommendationOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Acceptance",
    value: "accept",
  },
  {
    label: "Rejection",
    value: "reject",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const decisionOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const copyrightedOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Is copyrighted",
    value: "isCopyrighted",
  },
  {
    label: "Is not copyrighted",
    value: "isNotCopyrighted",
  },
];

export const registeredOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Registered",
    value: "registered",
  },
  {
    label: "Not registered",
    value: "notRegistered",
  },
];

export const finalVersionOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Has submitted final version",
    value: "hasFinalVersion",
  },
  {
    label: "Has not submitted final version",
    value: "noFinalVersion",
  },
];

export const conflictsOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "There is a conflict of interest with some of the track chairs",
    value: "hasConflicts",
  },
  {
    label: "There isn't a conflict of interest with some of the track chairs",
    value: "noConflicts",
  },
];

export const discordantOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Consistent review scores",
    value: "discordant",
  },
  {
    label: "No consistent review scores",
    value: "noDiscordant",
  },
];

export const templatesFiltersOptions = [
  {
    label: "All that are registered with no final submission",
    value: "registered-no-final-submission",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: undefined,
      copyrighted: undefined,
      registered: true,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: false,
    },
  },
  {
    label: "All that are registered with final submission but no copyright",
    value: "registered-final-version-no-copyright",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: undefined,
      copyrighted: false,
      registered: true,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: true,
    },
  },
  {
    label: "All that are registered with final submission and copyright",
    value: "registered-final-version-copyright",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: undefined,
      copyrighted: true,
      registered: true,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: true,
    },
  },
  {
    label: "All that are registered but are rejected",
    value: "registered-rejected",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "rejected",
      copyrighted: undefined,
      registered: true,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: undefined,
    },
  },
  {
    label: "All that are accepted but not registered",
    value: "accepted-not-registered",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "accepted",
      copyrighted: undefined,
      registered: false,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: undefined,
    },
  },
  {
    label: "All accepted with final submission, copyright and registered",
    value: "accepted-final-submission-copyright-registered",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "accepted",
      copyrighted: true,
      registered: true,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: true,
    },
  },
  {
    label: "All accepted with final submission with missing copyright",
    value: "accepted-final-submission-no-copyright",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "accepted",
      copyrighted: false,
      registered: undefined,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: true,
    },
  },
  {
    label:
      "All accepted with final submission and copyright but not registered",
    value: "accepted-final-version-copyright-no-registered",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "accepted",
      copyrighted: true,
      registered: false,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: true,
    },
  },
  {
    label: "All accepted with final submission and copyright",
    value: "accepted-final-submission-copyright",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "accepted",
      copyrighted: true,
      registered: undefined,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: true,
    },
  },
  {
    label: "All accepted that have no final submission",
    value: "accepted-no-final-version",
    filters: {
      status: undefined,
      reviewersInvitedMin: undefined,
      reviewersInvitedMax: undefined,
      reviewersAgreedMin: undefined,
      reviewersAgreedMax: undefined,
      reviewsMin: undefined,
      reviewsMax: undefined,
      averageScoreMin: undefined,
      averageScoreMax: undefined,
      recommendation: undefined,
      decision: "accepted",
      copyrighted: undefined,
      registered: undefined,
      hasOrganizerConflict: undefined,
      isScoreDiscordant: undefined,
      submittedFinal: false,
    },
  },
];
