const LOCAL_STORAGE_STATE = "local_storage_state";

class StateLoader {
  loadState() {
    try {
      let serializedState = localStorage.getItem(LOCAL_STORAGE_STATE);

      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state) {
    try {
      let serializedState = JSON.stringify(state);
      localStorage.setItem(LOCAL_STORAGE_STATE, serializedState);
    } catch (err) {}
  }

  initializeState() {
    // There are not initialized state
    return {};
  }
}

export default StateLoader;
