import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quickFilters: null,
};

const slice = createSlice({
  name: "quickFilters",
  initialState,
  reducers: {
    setQuickFilters(state, action) {
      const { quickFilters } = action.payload;
      state.quickFilters = quickFilters;
    },
  },
});

export const setQuickFilters = (quickFilters) => async (dispatch) => {
  dispatch(
    slice.actions.setQuickFilters({
      quickFilters,
    })
  );
};

export const { reducer } = slice;

export default slice;
