import { toast } from "react-hot-toast";
import Typography from "@mui/material/Typography";

export const useToast = () => {
  const triggerError = (error, template = "") => {
    const errorMessage =
      error?.response?.data?.message || "An unknown error has ocurred.";

    const message = `${template}${template && ":"} ${errorMessage}`;

    toast.error(<Typography variant="subtitle2">{message}</Typography>, {
      id: "unknown-error",
    });
  };

  return { triggerError };
};
