import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardFilter: null,
};

const slice = createSlice({
  name: "dashboardFilter",
  initialState,
  reducers: {
    setDashboardFilter(state, action) {
      const { dashboardFilter } = action.payload;
      state.dashboardFilter = dashboardFilter;
    },
  },
});

export const setDashboardFilter = (dashboardFilter) => async (dispatch) => {
  dispatch(
    slice.actions.setDashboardFilter({
      dashboardFilter,
    })
  );
};

export const { reducer } = slice;

export default slice;
