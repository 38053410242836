import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cachedUsers: [],
};

const slice = createSlice({
  name: "cachedUsers",
  initialState,
  reducers: {
    addUser(state, action) {
      const { user } = action.payload;

      state.cachedUsers = [...state.cachedUsers, user];
    },
  },
});

export const addUser = (user) => async (dispatch) => {
  dispatch(slice.actions.addUser({ user }));
};

export const { reducer } = slice;

export default slice;
