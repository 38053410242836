import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  asked: [],
  assigned: [],
  bids: [],
};

const slice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    getAsked(state, action) {
      const { asked } = action.payload;

      state.asked = asked;
    },
    getAssigned(state, action) {
      const { assigned } = action.payload;

      state.assigned = assigned;
    },
    getBids(state, action) {
      const { bids } = action.payload;

      state.bids = bids;
    },
  },
});

export const getAsked = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.ASSIGNMENTS_ENDPOINT);

    dispatch(
      slice.actions.getAsked({
        asked: response.data.filter(
          (assignment) => assignment.accepted === null
        ),
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAssigned = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.ASSIGNMENTS_ENDPOINT);

    dispatch(
      slice.actions.getAssigned({
        assigned: response.data.filter(
          (assignment) => assignment.accepted && !assignment.hasReview
        ),
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const getBids = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.BIDS_ENDPOINT);

    dispatch(
      slice.actions.getBids({
        bids: getBidsStats(response.data),
      })
    );
  } catch (error) {
    console.log(error);
  }
};

const getBidsStats = (apiData) => {
  let stats = [];

  apiData.forEach((bid) => {
    const track = bid.paper.track.name;
    const trackId = bid.paper.track.id;

    const trackIndex = stats.findIndex((element) => element.track === track);

    if (trackIndex !== -1) {
      stats[trackIndex].papers.push(bid.paper);
    } else {
      stats.push({
        track,
        trackId,
        event: bid.paper.track.event.code,
        assigner: bid.assigner,
        invitationDate: bid.invitationDate,
        deadline: bid.deadline,
        papers: [bid.paper],
      });
    }
  });

  return stats;
};

export const { reducer } = slice;

export default slice;
