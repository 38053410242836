import { createContext, useEffect, useReducer } from "react";
import { useDispatch } from "../redux/store";
import axios from "axios";
import PropTypes from "prop-types";
import { StatusCodes } from "http-status-codes";
import * as ENDPOINTS from "../api/endpoints";
import { logout as customLogout } from "../redux/slices/logOut";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "USER_CREDENTIALS",
  login: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const customDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get(ENDPOINTS.AUTH_IS_AUTHENTICATED);
        const { status, data } = response;

        if (status === StatusCodes.OK && data) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: data,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(ENDPOINTS.AUTH_LOGIN, {
      email,
      password,
    });

    const { status, data } = response;

    if (status === StatusCodes.OK && data) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: data,
        },
      });
    }
  };

  const updateProfile = async (user) => {
    try {
      const response = await axios.get(ENDPOINTS.AUTH_IS_AUTHENTICATED);
      const { status, data } = response;

      if (status === StatusCodes.OK && data) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: data,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const logout = async () => {
    const response = await axios.get(ENDPOINTS.AUTH_LOG_OUT);
    const { status } = response;

    if (status === StatusCodes.OK) {
      dispatch({ type: "LOGOUT" });
      customDispatch(customLogout());
    }
  };

  const register = async (user) => {
    let error = null;

    const response = await axios.post(ENDPOINTS.AUTH_REGISTER, user);

    if (response.data && response.data.message) {
      error = response.data.message;
    }

    return error;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "USER_CREDENTIALS",
        login,
        updateProfile,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
