import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import StateLoader from "./StateLoader";

const stateLoader = new StateLoader();

const appReducer = (state, action) => {
  if (action.type === "app/logout") {
    state = undefined;
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer: appReducer,
  preloadedState: stateLoader.loadState(),
});

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
