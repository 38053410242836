import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  submissionsStats: [],
  bidsStats: [],
  submissions: [],
  bids: [],
};

const slice = createSlice({
  name: "decisions",
  initialState,
  reducers: {
    getSubmissionsStats(state, action) {
      const { submissionsStats } = action.payload;

      state.submissionsStats = submissionsStats;
    },
    getBidsStats(state, action) {
      const { bidsStats } = action.payload;

      state.bidsStats = bidsStats;
    },
    getSubmissions(state, action) {
      const { submissions } = action.payload;

      state.submissions = submissions;
    },
    getBids(state, action) {
      const { bids } = action.payload;

      state.bids = bids;
    },
  },
});

export const getSubmissionsStats = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.SUBMISSIONS_DECISIONS_STATS);

    dispatch(
      slice.actions.getSubmissionsStats({ submissionsStats: response.data })
    );
  } catch (error) {
    console.log(error);
  }
};

export const getBidsStats = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.BIDS_DECISIONS_STATS);

    dispatch(slice.actions.getBidsStats({ bidsStats: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const getSubmissions = (trackId) => async (dispatch) => {
  try {
    const response = await axios.get("/tracks/" + trackId + "/acceptance");

    dispatch(slice.actions.getSubmissions({ submissions: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const getBids = (trackId) => async (dispatch) => {
  try {
    const response = await axios.get("/tracks/" + trackId + "/bidding");

    dispatch(slice.actions.getBids({ bids: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
