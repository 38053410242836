import { lightShadows } from "./shadows";

export const IES_THEME_OPTIONS = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 0.86,
            color: "#42526e",
          },
        },
      },
    },
  },
  palette: {
    action: {
      active: "#6b778c",
    },
    background: {
      default: "#F9FAFC",
      paper: "#ffffff",
    },
    error: {
      contrastText: "#ffffff",
      main: "#f44336",
    },
    mode: "light",
    primary: {
      contrastText: "#ffffff",
      main: "#3C4693",
    },
    success: {
      contrastText: "#ffffff",
      main: "#4caf50",
    },
    text: {
      secondary: "#6b778c",
      success: "#4caf50",
      palidSecondary: "#f50057",
      palidSuccess: "#33BB78",
      palidMute: "#BDBDBD",
      corporate: "#eb8b00",
      white: "#ffffff",
    },
    warning: {
      contrastText: "#ffffff",
      main: "#ff9800",
    },
    corporate: {
      contrastText: "#ffffff",
      main: "#eb8b00",
    },
    palidSuccess: {
      contrastText: "#ffffff",
      main: "#14b8a5",
    },
    palidMute: {
      contrastText: "#ffffff",
      main: "#BDBDBD",
    },
    palidSecondary: {
      contrastText: "#ffffff",
      main: "#f50057",
    },
    secondary: {
      contrastText: "#ffffff",
      main: "#f50057",
    },
    light: {
      contrastText: "#ffffff",
      main: "#ffffff",
    },
    mute: {
      contrastText: "#ffffff",
      main: "#6b778c",
    },
    dark: {
      contrastText: "#ffffff",
      main: "#172b4d",
    },
  },
  shadows: lightShadows,
};
