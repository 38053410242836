import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar, Typography, Button, Link } from "@mui/material";
import { styled } from "@mui/system";
import SocietyLogo from "../../components/society-logo";
import { LOGO_VARIANTS, SOCIETIES } from "../../theme/societies";

const PublicBannerRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const society = process.env.REACT_APP_IEEE_SOCIETY;

const PublicBanner = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <PublicBannerRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <RouterLink to="/home/welcome">
            <SocietyLogo variant={LOGO_VARIANTS.WHITE} />
          </RouterLink>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <Box sx={{ display: "flex", alignItems: "center", ml: 8 }}>
            <RouterLink to="/home/welcome">
              <SocietyLogo variant={LOGO_VARIANTS.WHITE} />
            </RouterLink>
            <Typography sx={{ ml: 2, fontFamily: "sans-serif" }} variant="h6">
              Conferences Community
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box
          sx={{
            alignItems: "center",
            display: {
              md: "flex",
              xs: "none",
            },
            mr: 8,
          }}
        >
          {society === SOCIETIES.IES && (
            <>
              <Link
                href="/app/society/technical-co-sponsorship-applications/new"
                component="a"
                color="text.white"
                underline="none"
                variant="subtitle2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Technical Co-sponsorship
              </Link>
              <Link
                href="/app/society/system-activation-applications/new"
                component="a"
                color="text.white"
                sx={{ ml: 2 }}
                underline="none"
                variant="subtitle2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Request for Submission System
              </Link>
              <Link
                href="/app/society/sponsored-conference-applications/new"
                color="text.white"
                component="a"
                sx={{ ml: 2 }}
                underline="none"
                variant="subtitle2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sponsored Conference
              </Link>
            </>
          )}
          <Link
            href="/app/docs/welcome"
            color="text.white"
            component="a"
            sx={{ ml: 2 }}
            underline="none"
            variant="subtitle2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Documentation
          </Link>
          <Button
            color="corporate"
            component={RouterLink}
            variant="contained"
            sx={{ ml: 3 }}
            to="/authentication/login"
          >
            Sign In
          </Button>
        </Box>
      </Toolbar>
    </PublicBannerRoot>
  );
};

PublicBanner.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default PublicBanner;
