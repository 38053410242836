import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSnapshot: {},
};

const slice = createSlice({
  name: "selectedSnapshot",
  initialState,
  reducers: {
    setSnapshot(state, action) {
      const { snapshot } = action.payload;
      state.selectedSnapshot = snapshot;
    },
    resetSnapshot(state) {
      state.selectedSnapshot = initialState.selectedSnapshot;
    },
  },
});

export const setSnapshot = (snapshot) => async (dispatch) => {
  dispatch(
    slice.actions.setSnapshot({
      snapshot,
    })
  );
};

export const resetSnapshot = () => async (dispatch) => {
  dispatch(slice.actions.resetSnapshot());
};

export const { reducer } = slice;

export default slice;
