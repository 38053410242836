import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/system";
import SocietyLogo from "../../../components/society-logo";
import { LOGO_VARIANTS } from "../../../theme/societies";

const AuthBannerRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const AuthBanner = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <AuthBannerRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <RouterLink to="/home/welcome">
            <SocietyLogo variant={LOGO_VARIANTS.WHITE} />
          </RouterLink>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RouterLink to="/home/welcome">
              <SocietyLogo variant={LOGO_VARIANTS.WHITE} />
            </RouterLink>
            <Typography sx={{ ml: 2, fontFamily: "sans-serif" }} variant="h6">
              Conferences Community
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AuthBannerRoot>
  );
};

AuthBanner.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default AuthBanner;
