import { useQuery } from "@tanstack/react-query";
import {
  getTracks,
  getTracksStatsPaperReviews,
  getTracksStatsPaperInvitations,
  getTracksStatsPaperStatus,
  getTracksStatsPaperConflicts,
  getTracksStatsPaperRecommendations,
  getTracksStatsInvitations,
} from "../api/tracks";

const key = "tracks";

export const useTracks = () => {
  return useQuery([key], getTracks);
};

export const useTracksStatsInvitations = () => {
  return useQuery([key, "stats", "invitations"], getTracksStatsInvitations);
};

export const useTracksStatsPaperStatus = () => {
  return useQuery([key, "stats", "paper-status"], getTracksStatsPaperStatus);
};

export const useTracksStatsPaperInvitations = () => {
  return useQuery(
    [key, "stats", "paper-invitations"],
    getTracksStatsPaperInvitations
  );
};

export const useTracksStatsPaperReviews = () => {
  return useQuery([key, "stats", "paper-reviews"], getTracksStatsPaperReviews);
};

export const useTracksStatsPaperConflicts = () => {
  return useQuery(
    [key, "stats", "paper-conflicts"],
    getTracksStatsPaperConflicts
  );
};

export const useTracksStatsPaperRecommendations = () => {
  return useQuery(
    [key, "stats", "paper-recommendations"],
    getTracksStatsPaperRecommendations
  );
};
