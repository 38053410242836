import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as endpoints from "../../api/endpoints";

const initialState = {
  tracks: [],
  stats: [],
};

const slice = createSlice({
  name: "tracks",
  initialState,
  reducers: {
    getTracks(state, action) {
      const { tracks } = action.payload;

      state.tracks = tracks;
    },
    getStats(state, action) {
      const { stats } = action.payload;

      state.stats = stats;
    },
  },
});

export const getTracks = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.TRACKS);

    dispatch(slice.actions.getTracks({ tracks: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const getStats = () => async (dispatch) => {
  try {
    const response = await axios.get(endpoints.TRACKS_STATS);

    dispatch(slice.actions.getStats({ stats: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const { reducer } = slice;

export default slice;
