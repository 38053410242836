import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { AuthBanner } from "../modules/authentication/components";
import Footer from "./components/footer";

const MainLayout = ({ children }) => {
  return (
    <div>
      <AuthBanner />
      {children || <Outlet />}
      <Footer />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
