import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DraftsIcon from "@mui/icons-material/Drafts";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DescriptionIcon from "@mui/icons-material/Description";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import QuizIcon from "@mui/icons-material/Quiz";
import GavelIcon from "@mui/icons-material/Gavel";
import PersonIcon from "@mui/icons-material/Person";
import ViewListIcon from "@mui/icons-material/ViewList";
import useAuth from "../../hooks/useAuth";
import Scrollbar from "../../components/scrollbar";
import * as constants from "../../constants";
import {
  DateRange,
  FactCheck,
  Groups,
  NoteAltOutlined,
  InsertChartOutlined,
  PrivacyTipOutlined,
} from "@mui/icons-material";
import NavSection from "./nav-section";
import SocietyLogo from "../../components/society-logo";
import { LOGO_VARIANTS, SOCIETIES } from "../../theme/societies";

const society = process.env.REACT_APP_IEEE_SOCIETY;

const sections = [
  {
    title: "General",
    isVisible: true,
    roles: [constants.USER_ROLES.USER],
    items: [
      {
        title: "Submit Manuscript",
        path: "/app/general/conferences",
        icon: <DescriptionIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Calendar",
        path: "/app/general/calendar",
        icon: <DateRange fontSize="small" />,
        allowed: [constants.USER_ROLES.USER],
      },
      {
        title: "Account",
        path: "/app/general/account",
        icon: <PersonIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.USER],
      },
    ],
  },
  {
    title: "Administration",
    isVisible: true,
    roles: [
      constants.USER_ROLES.ADMIN,
      constants.USER_ROLES.GCHAIR,
      constants.USER_ROLES.TPCHAIR,
      constants.USER_ROLES.SSCC,
      constants.USER_ROLES.VP_WORKSHOPS,
      constants.USER_ROLES.VP_CONFERENCES,
    ],
    items: [
      {
        title: "Conferences",
        path: "/app/administration/conferences",
        icon: <EventIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.GCHAIR,
          constants.USER_ROLES.TPCHAIR,
          constants.USER_ROLES.SSCC,
          constants.USER_ROLES.VP_WORKSHOPS,
          constants.USER_ROLES.VP_CONFERENCES,
        ],
      },
      {
        title: "Privacy preferences",
        path: "/app/administration/privacy",
        icon: <PrivacyTipOutlined fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN],
      },
      {
        title: "Conference promotion",
        path: "/app/administration/promotion",
        icon: <Groups fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN],
      },
    ],
  },
  {
    title: "Assistance",
    isVisible: society === SOCIETIES.IES,
    roles: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.SYPA_CHAIR],
    items: [
      {
        title: "IEEE-IES SYPA",
        path: "/app/ieee-ies-sypa-assistance/applications",
        icon: <NoteAltOutlined fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.SYPA_CHAIR],
      },
    ],
  },
  {
    title: "Statistics",
    isVisible: true,
    roles: [
      constants.USER_ROLES.ADMIN,
      constants.USER_ROLES.CONFERENCES_COMMITTEE,
    ],
    items: [
      {
        title: "Conferences overview",
        path: "/app/statistics/conferences/overview",
        icon: <InsertChartOutlined fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
      {
        title: "Manuscripts evolution",
        path: "/app/statistics/conferences/manuscripts-evolution",
        icon: <TimelineIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
      {
        title: "Manuscripts overview",
        path: "/app/statistics/conferences/manuscripts-overview",
        icon: <StackedLineChartIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
    ],
  },
  {
    title: "Management",
    isVisible: true,
    roles: [
      constants.USER_ROLES.ADMIN,
      constants.USER_ROLES.GCHAIR,
      constants.USER_ROLES.TPCHAIR,
      constants.USER_ROLES.SSCC,
      constants.USER_ROLES.TRACKCHAIR,
    ],
    items: [
      {
        title: "Dashboard",
        path: "/app/management/dashboard",
        icon: <DashboardIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.GCHAIR,
          constants.USER_ROLES.TPCHAIR,
          constants.USER_ROLES.SSCC,
          constants.USER_ROLES.TRACKCHAIR,
        ],
      },
      {
        title: "Tracks",
        path: "/app/management/tracks",
        icon: <ViewListIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.GCHAIR,
          constants.USER_ROLES.TPCHAIR,
          constants.USER_ROLES.SSCC,
          constants.USER_ROLES.TRACKCHAIR,
        ],
      },
      {
        title: "Manuscripts",
        path: "/app/management/submissions",
        icon: <LibraryBooksIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.GCHAIR,
          constants.USER_ROLES.TPCHAIR,
          constants.USER_ROLES.SSCC,
          constants.USER_ROLES.TRACKCHAIR,
        ],
      },
      {
        title: "Review Invitations",
        path: "/app/management/invitations",
        icon: <DraftsIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.GCHAIR,
          constants.USER_ROLES.TPCHAIR,
          constants.USER_ROLES.SSCC,
          constants.USER_ROLES.TRACKCHAIR,
        ],
        children: [
          {
            title: "Manuscripts",
            path: "/app/management/invitations/reviews",
          },
          {
            title: "Reviewers",
            path: "/app/management/invitations/reviews/reviewers",
          },
          {
            title: "Bidding",
            path: "/app/management/invitations/bids",
          },
        ],
      },
      {
        title: "Decisions",
        path: "/app/management/decisions",
        icon: <AssignmentTurnedInIcon fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.GCHAIR,
          constants.USER_ROLES.TPCHAIR,
          constants.USER_ROLES.SSCC,
          constants.USER_ROLES.TRACKCHAIR,
        ],
        children: [
          {
            title: "Manuscripts",
            path: "/app/management/decisions/submissions",
          },
          {
            title: "Bids",
            path: "/app/management/decisions/bids",
          },
        ],
      },
    ],
  },
  {
    title: "Bidding",
    isVisible: true,
    roles: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.BIDDER],
    items: [
      {
        title: "Bids",
        path: "/app/bidding/bids/asked",
        icon: <GavelIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.BIDDER],
      },
    ],
  },
  {
    title: "Reviews",
    isVisible: true,
    roles: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.REVIEWER],
    items: [
      {
        title: "Asked",
        path: "/app/reviews/asked",
        icon: <QuizIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.REVIEWER],
      },
      {
        title: "Drafts",
        path: "/app/reviews/drafts",
        icon: <PendingActionsIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.REVIEWER],
      },
      {
        title: "Done",
        path: "/app/reviews/done",
        icon: <FactCheckIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.REVIEWER],
      },
    ],
  },
  {
    title: "Manuscripts",
    isVisible: true,
    roles: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.AUTHOR],
    items: [
      {
        title: "Manuscripts",
        path: "/app/submissions/current",
        icon: <LibraryBooksIcon fontSize="small" />,
        allowed: [constants.USER_ROLES.ADMIN, constants.USER_ROLES.AUTHOR],
      },
    ],
  },
  {
    title: "Processes",
    isVisible: society === SOCIETIES.IES,
    roles: [
      constants.USER_ROLES.ADMIN,
      constants.USER_ROLES.VP_WORKSHOPS,
      constants.USER_ROLES.VP_CONFERENCES,
      constants.USER_ROLES.CONFERENCES_COMMITTEE,
    ],
    items: [
      {
        title: "Technical Co-sponsorships",
        path: "/app/society/technical-co-sponsorship-applications",
        icon: <FactCheck fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.VP_WORKSHOPS,
          constants.USER_ROLES.VP_CONFERENCES,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
      {
        title: "Submission System Requests",
        path: "/app/society/system-activation-applications",
        icon: <FactCheck fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.VP_WORKSHOPS,
          constants.USER_ROLES.VP_CONFERENCES,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
      {
        title: "Operating Agreements",
        path: "/app/society/operating-agreements",
        icon: <FactCheck fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.VP_WORKSHOPS,
          constants.USER_ROLES.VP_CONFERENCES,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
      {
        title: "Sponsored Conferences",
        path: "/app/society/sponsored-conference-applications",
        icon: <FactCheck fontSize="small" />,
        allowed: [
          constants.USER_ROLES.ADMIN,
          constants.USER_ROLES.VP_WORKSHOPS,
          constants.USER_ROLES.VP_CONFERENCES,
          constants.USER_ROLES.CONFERENCES_COMMITTEE,
        ],
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile, handleSelectItem } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (!openMobile) {
      onMobileClose();
    }
  }, [onMobileClose, openMobile]);

  const isAllowed = (allowedRoles) => {
    let allowedRolesCount = 0;

    allowedRoles.forEach((allowedRole) => {
      if (user.roles.includes(allowedRole)) {
        allowedRolesCount++;
      }
    });

    return allowedRolesCount > 0;
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ mt: 8, display: { lg: "none", xs: "block" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/home/welcome">
              <SocietyLogo variant={LOGO_VARIANTS.DEFAULT} />
            </RouterLink>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              backgroundColor: "#f4f5f7",
              px: 3,
              py: 2,
              borderRadius: 3,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "fontWeightMedium" }}
                color="textPrimary"
                variant="body2"
              >
                {user.firstName + " " + user.lastName}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {user.affiliation}
              </Typography>
            </div>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections
            .filter((section) => isAllowed(section.roles) && section.isVisible)
            .map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                handleSelectItem={handleSelectItem}
                sx={{
                  "& + &": {
                    mt: 3,
                  },
                }}
                {...section}
              />
            ))}
        </Box>
        <Divider />
        <Box sx={{ alignSelf: "flex-end", p: 2, bottom: 0 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/app/docs/welcome"
            variant="contained"
          >
            Documentation
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        sx={{ display: { lg: "none", xs: "block" } }}
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: 275,
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        sx={{ display: { xs: "none", lg: "block" } }}
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 275,
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
