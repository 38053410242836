import { combineReducers } from "@reduxjs/toolkit";
import { reducer as eventsReducer } from "../slices/events";
import { reducer as conferencesReducer } from "../slices/conferences";
import { reducer as submissionsReducer } from "../slices/submissions";
import { reducer as assignmentsReducer } from "../slices/assingments";
import { reducer as reviewsReducer } from "../slices/reviews";
import { reducer as bidsReducer } from "../slices/bids";
import { reducer as decisionsReducer } from "../slices/decisions";
import { reducer as tracksReducer } from "../slices/tracks";
import { reducer as selectedEvent } from "../slices/selectedEvent";
import { reducer as selectedTrack } from "../slices/selectedTrack";
import { reducer as openManuscripts } from "../slices/openManuscripts";
import { reducer as logOut } from "../slices/logOut";
import { reducer as dashboardFilter } from "../slices/dashboardFilter";
import { reducer as quickFilters } from "../slices/quickFilters";
import { reducer as filterItems } from "../slices/filterItems";
import { reducer as cachedUsers } from "../slices/cached-users";
import { reducer as visibleColumns } from "../slices/visible-columns";
import { reducer as manuscriptFilters } from "../slices/manuscript-filters";
import { reducer as inviteReviewers } from "../slices/invite-reviewers";
import { reducer as manuscriptsPagination } from "../slices/manuscripts-pagination";
import { reducer as selectedSnapshot } from "../slices/selectedSnapshot";

const rootReducer = combineReducers({
  conferences: conferencesReducer,
  events: eventsReducer,
  submissions: submissionsReducer,
  assignments: assignmentsReducer,
  reviews: reviewsReducer,
  bids: bidsReducer,
  decisions: decisionsReducer,
  tracks: tracksReducer,
  selectedEvent: selectedEvent,
  selectedTrack: selectedTrack,
  openManuscripts: openManuscripts,
  logOut: logOut,
  dashboardFilter: dashboardFilter,
  quickFilters: quickFilters,
  filterItems: filterItems,
  cachedUsers: cachedUsers,
  visibleColumns: visibleColumns,
  manuscriptFilters: manuscriptFilters,
  inviteReviewers: inviteReviewers,
  manuscriptsPagination: manuscriptsPagination,
  selectedSnapshot: selectedSnapshot,
});

export default rootReducer;
