import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../redux/store";
import { setTrack, resetSelectedTrack } from "../../redux/slices/selectedTrack";
import { Menu, MenuItem, Typography, Tooltip, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as sorters from "../../utils/sorters";
import usePrevious from "../../hooks/usePrevious";
import { useTracks } from "../../hooks/tracks";
import { useToast } from "../../hooks/useToast";
import { ViewList, KeyboardArrowDown } from "@mui/icons-material";
import { resetPagination } from "../../redux/slices/manuscripts-pagination";

const TracksMenu = () => {
  const dispatch = useDispatch();
  const { triggerError } = useToast();
  const [tracks, setTracks] = useState([]);
  const { data: fetchedTracks, error, isLoading } = useTracks();
  const [filteredTracks, setFilteredTracks] = useState([]);
  const [trackAnchorEl, setTrackAnchorEl] = useState(null);
  const { selectedEvent } = useSelector((state) => state.selectedEvent);
  const { selectedTrack } = useSelector((state) => state.selectedTrack);
  const prevEvent = usePrevious(selectedEvent);

  if (error) {
    triggerError(error, "Error while fetching tracks");
  }

  useEffect(() => {
    if (fetchedTracks?.length > 0) {
      const trackIds = fetchedTracks.map((track) => track.id);
      const uniqueTrackIds = Array.from(new Set(trackIds));

      const uniqueTracks = uniqueTrackIds.map((id) => {
        return fetchedTracks.find((track) => track.id === id);
      });

      setTracks(uniqueTracks);
    }
  }, [fetchedTracks]);

  useEffect(() => {
    if (selectedEvent.code) {
      setFilteredTracks(
        tracks.filter((t) => t.event.code === selectedEvent.code)
      );
    } else {
      setFilteredTracks(tracks);
    }

    // Handle event change
    if (
      prevEvent &&
      selectedEvent.code &&
      prevEvent.code !== selectedEvent.code &&
      selectedTrack.event?.code !== selectedEvent.code
    ) {
      // Reset selected track value
      dispatch(resetSelectedTrack());
    }
  }, [dispatch, prevEvent, selectedEvent, selectedTrack, tracks]);

  const handleTrackSortOpen = (event) => {
    setTrackAnchorEl(event.currentTarget);
  };

  const handlerTrackSortClose = () => {
    setTrackAnchorEl(null);
  };

  const handleTrackSortSelect = (value) => {
    dispatch(setTrack(value));
    dispatch(resetPagination());
    setTrackAnchorEl(null);
  };

  const handleResetTrack = () => {
    dispatch(resetSelectedTrack());
    dispatch(resetPagination());
    setTrackAnchorEl(null);
  };

  const selectedTrackText = selectedTrack.name
    ? selectedTrack.name
    : `My tracks (${filteredTracks.length})`;

  return (
    <>
      <LoadingButton
        color="light"
        aria-controls="events-menu"
        aria-haspopup="true"
        startIcon={<ViewList fontSize="small" />}
        endIcon={<KeyboardArrowDown fontSize="small" />}
        variant="text"
        onClick={handleTrackSortOpen}
        loading={isLoading}
        loadingPosition="start"
      >
        <Tooltip title={selectedTrackText}>
          <Typography
            sx={{
              maxWidth: 360,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="inherit"
          >
            {selectedTrackText}
          </Typography>
        </Tooltip>
      </LoadingButton>
      <Menu
        id="track-menu"
        anchorEl={trackAnchorEl}
        keepMounted
        open={Boolean(trackAnchorEl)}
        onClose={handlerTrackSortClose}
      >
        <MenuItem
          key="default-track"
          sx={{ minWidth: 150 }}
          onClick={() => handleResetTrack()}
        >
          {`My tracks (${filteredTracks.length})`}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {filteredTracks.sort(sorters.sortByName).map((track) => (
          <MenuItem
            key={track.id}
            sx={{ minWidth: 150 }}
            onClick={() => handleTrackSortSelect(track)}
          >
            {track.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TracksMenu;
