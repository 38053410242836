import { Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthGuard from "./modules/authentication/guards/auth-guard";
import GuestGuard from "./modules/authentication/guards/guest-guard";
import LoadingScreen from "./components/loading-screen";
import AuthLayout from "./layouts/auth-layout";
import PublicLayout from "./layouts/public-layout";
import DashboardLayout from "./layouts/dashboard-layout";
import DocumentationLayout from "./layouts/documentation-layout";

// Redirects
import SubmitRedirection from "./components/redirects/submit-redirection";
import ChangePasswordRedirection from "./components/redirects/change-password-redirection";
import VerifyAccountRedirection from "./components/redirects/verify-account-redirection";
import ReviewDoneDetailRedirect from "./components/redirects/review-done-detail-redirect";
import ReviewAskedDetailRedirect from "./components/redirects/review-asked-detail-redirect";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Home

const Home = Loadable(lazy(() => import("./modules/home/pages/home")));

// Docs pages

const WelcomeSection = Loadable(
  lazy(() => import("./modules/documentation/pages/welcome-section"))
);
const RolesHierarchySection = Loadable(
  lazy(() => import("./modules/documentation/pages/roles-hierarchy-section"))
);
const VideoTutorialSection = Loadable(
  lazy(() => import("./modules/documentation/pages/video-tutorial-section"))
);
const ForgotPasswordSection = Loadable(
  lazy(() => import("./modules/documentation/pages/forgot-password-section"))
);
const AccountSection = Loadable(
  lazy(() => import("./modules/documentation/pages/account-section"))
);
const SubmitPaperSection = Loadable(
  lazy(() => import("./modules/documentation/pages/submit-paper-section"))
);
const SubmitFinalSection = Loadable(
  lazy(() => import("./modules/documentation/pages/submit-final-section"))
);
const SignCopyrightSection = Loadable(
  lazy(() => import("./modules/documentation/pages/sign-copyright-section"))
);
const PaperDetailSection = Loadable(
  lazy(() => import("./modules/documentation/pages/paper-detail-section"))
);
const EditPaperSection = Loadable(
  lazy(() => import("./modules/documentation/pages/edit-paper-section"))
);
const PaperFeedbacksSection = Loadable(
  lazy(() => import("./modules/documentation/pages/paper-feedbacks-section"))
);
const ReviewInvitationSection = Loadable(
  lazy(() => import("./modules/documentation/pages/review-invitation-section"))
);
const ReviewAssignmentSection = Loadable(
  lazy(() => import("./modules/documentation/pages/review-assignment-section"))
);
const SubmitReviewSection = Loadable(
  lazy(() => import("./modules/documentation/pages/submit-review-section"))
);
const ReviewsDoneSection = Loadable(
  lazy(() => import("./modules/documentation/pages/reviews-done-section"))
);
const BidsDecisionSection = Loadable(
  lazy(() => import("./modules/documentation/pages/bids-decision-section"))
);
const RecommendationsSection = Loadable(
  lazy(() => import("./modules/documentation/pages/recommendations-section"))
);
const SubmissionsDecisionSection = Loadable(
  lazy(() =>
    import("./modules/documentation/pages/submission-decision-section")
  )
);
const ManageConferenceSection = Loadable(
  lazy(() => import("./modules/documentation/pages/manage-conference-section"))
);
const ContactSupportSection = Loadable(
  lazy(() => import("./modules/documentation/pages/contact-support-section"))
);
const FAQSection = Loadable(
  lazy(() => import("./modules/documentation/pages/faq-section"))
);
const DashboardOverviewSection = Loadable(
  lazy(() => import("./modules/documentation/pages/dashboard-overview-section"))
);
const ManageTracksSection = Loadable(
  lazy(() => import("./modules/documentation/pages/manage-tracks-section"))
);
const ManageSubmissionsSection = Loadable(
  lazy(() => import("./modules/documentation/pages/manage-submissions-section"))
);
const InviteReviewersSection = Loadable(
  lazy(() => import("./modules/documentation/pages/invite-reviewers-section"))
);
const InviteBiddersSection = Loadable(
  lazy(() => import("./modules/documentation/pages/invite-bidders-section"))
);
const ManageInvitationsSection = Loadable(
  lazy(() => import("./modules/documentation/pages/manage-invitations-section"))
);
const WithdrawPaperSection = Loadable(
  lazy(() => import("./modules/documentation/pages/withdraw-paper-section"))
);
const ChangeTrackSection = Loadable(
  lazy(() => import("./modules/documentation/pages/change-track-section"))
);

// Authentication pages

const Login = Loadable(
  lazy(() => import("./modules/authentication/pages/login"))
);
const Register = Loadable(
  lazy(() => import("./modules/authentication/pages/register"))
);
const PasswordRecovery = Loadable(
  lazy(() => import("./modules/authentication/pages/password-recovery"))
);
const ChangePassword = Loadable(
  lazy(() => import("./modules/authentication/pages/change-password"))
);
const AccountVerification = Loadable(
  lazy(() => import("./modules/authentication/pages/account-verification"))
);
const UnregisteredReviewReject = Loadable(
  lazy(() =>
    import("./modules/authentication/pages/unregistered-review-reject")
  )
);

// Administration pages

const ConferencesAdministration = Loadable(
  lazy(() =>
    import("./modules/administration/pages/conferences-administration")
  )
);
const CreateConference = Loadable(
  lazy(() => import("./modules/administration/pages/create-conference"))
);
const CreateTracks = Loadable(
  lazy(() => import("./modules/administration/pages/create-tracks"))
);
const EditConference = Loadable(
  lazy(() => import("./modules/administration/pages/edit-conference"))
);
const ConferenceDetail = Loadable(
  lazy(() => import("./modules/administration/pages/conference-detail"))
);
const PrivacyPreferences = Loadable(
  lazy(() => import("./modules/users/pages/privacy-preferences"))
);
const ConferencePromotion = Loadable(
  lazy(() => import("./modules/users/pages/conference-promotion"))
);

// Statistics

const ConferencesOverview = Loadable(
  lazy(() => import("./modules/statistics/pages/conferences-overview"))
);
const ManuscriptsEvolution = Loadable(
  lazy(() => import("./modules/statistics/pages/manuscripts-evolution"))
);
const ManuscriptsOverview = Loadable(
  lazy(() => import("./modules/statistics/pages/manuscripts-overview"))
);

// Conferences pages

const Conferences = Loadable(
  lazy(() => import("./modules/general/pages/conferences"))
);

const Calendar = Loadable(
  lazy(() => import("./modules/general/pages/calendar"))
);

// Submission pages

const InitialSubmission = Loadable(
  lazy(() => import("./modules/authors/pages/initial-submission"))
);
const EditSubmission = Loadable(
  lazy(() => import("./modules/authors/pages/edit-submission"))
);
const FinalSubmission = Loadable(
  lazy(() => import("./modules/authors/pages/final-submission"))
);

// Author menu pages

const CurrentSubmissions = Loadable(
  lazy(() => import("./modules/authors/pages/current-submissions"))
);
const ManuscriptDetail = Loadable(
  lazy(() => import("./modules/authors/pages/manuscript-detail"))
);
const CopyrightSignature = Loadable(
  lazy(() => import("./modules/copyright/pages/copyright-signature"))
);

// Reviewer menu pages

const AskedReviews = Loadable(
  lazy(() => import("./modules/reviews/pages/asked-reviews"))
);
const DraftReviews = Loadable(
  lazy(() => import("./modules/reviews/pages/draft-reviews"))
);
const AskedAssignmentDetail = Loadable(
  lazy(() => import("./modules/reviews/pages/asked-assignment-detail"))
);
const PerformBid = Loadable(
  lazy(() => import("./modules/bidding/pages/perform-bid"))
);
const DoneReviews = Loadable(
  lazy(() => import("./modules/reviews/pages/done-reviews"))
);
const ReviewManuscript = Loadable(
  lazy(() => import("./modules/reviews/pages/review-manuscript"))
);
const ReviewDetail = Loadable(
  lazy(() => import("./modules/reviews/pages/review-detail"))
);
const AskedBids = Loadable(
  lazy(() => import("./modules/bidding/pages/asked-bids"))
);

// Track chair pages

const Dashboard = Loadable(
  lazy(() => import("./modules/dashboard/pages/dashboard"))
);
const ManageTracks = Loadable(
  lazy(() => import("./modules/tracks/pages/manage-tracks"))
);
const ManuscriptsManagement = Loadable(
  lazy(() => import("./modules/manuscripts/pages/manuscripts-management"))
);
const ReviewInvitationsMgmt = Loadable(
  lazy(() => import("./modules/reviews/pages/review-invitations-mgmt"))
);
const ReviewersInvitations = Loadable(
  lazy(() => import("./modules/reviews/pages/reviewers-invitations"))
);
const BidInvitationsMgmt = Loadable(
  lazy(() => import("./modules/bidding/pages/bid-invitations-mgmt"))
);
const SubmissionsDecision = Loadable(
  lazy(() => import("./modules/decisions/pages/submissions-decision"))
);
const SubmissionsDecisionMgmt = Loadable(
  lazy(() => import("./modules/decisions/pages/submissions-decision-mgmt"))
);
const BidsDecision = Loadable(
  lazy(() => import("./modules/bidding/pages/bids-decision"))
);
const BidsDecisionMgmt = Loadable(
  lazy(() => import("./modules/bidding/pages/bids-decision-mgmt"))
);
const InviteReviewers = Loadable(
  lazy(() => import("./modules/reviews/pages/invite-reviewers"))
);
const InviteBidders = Loadable(
  lazy(() => import("./modules/bidding/pages/invite-bidders"))
);

// Society pages

const TCSRequestDetail = Loadable(
  lazy(() => import("./modules/society/pages/tcs-request-detail"))
);
const TCSRequestList = Loadable(
  lazy(() => import("./modules/society/pages/tcs-request-list"))
);
const TCSRequestCreate = Loadable(
  lazy(() => import("./modules/society/pages/tcs-request-create"))
);
const TCSRequestEdit = Loadable(
  lazy(() => import("./modules/society/pages/tcs-request-edit"))
);
const SystemActivationApplications = Loadable(
  lazy(() => import("./modules/society/pages/system-activation-applications"))
);
const SystemActivationApplicationDetail = Loadable(
  lazy(() =>
    import("./modules/society/pages/system-activation-application-detail")
  )
);
const OperatingAgreementSigning = Loadable(
  lazy(() => import("./modules/society/pages/operating-agreement-signing"))
);
const OperatingAgreements = Loadable(
  lazy(() => import("./modules/society/pages/operating-agreements"))
);
const SponsoredConferenceApplication = Loadable(
  lazy(() => import("./modules/society/pages/sponsored-conference-application"))
);
const SystemActivationApplication = Loadable(
  lazy(() => import("./modules/society/pages/system-activation-application"))
);
const SponsoredConferenceApplications = Loadable(
  lazy(() =>
    import("./modules/society/pages/sponsored-conference-applications")
  )
);
const SponsoredConferenceApplicationDetail = Loadable(
  lazy(() =>
    import("./modules/society/pages/sponsored-conference-application-detail")
  )
);
const SponsoredConferenceApplicationEdit = Loadable(
  lazy(() =>
    import("./modules/society/pages/sponsored-conference-application-edit")
  )
);
const SystemActivationApplicationEdit = Loadable(
  lazy(() =>
    import("./modules/society/pages/system-activation-application-edit")
  )
);

// Awards

const TravelAwardApplication = Loadable(
  lazy(() => import("./modules/awards/pages/travel-award-application"))
);
const TravelAwardApplicationEdit = Loadable(
  lazy(() => import("./modules/awards/pages/travel-award-application-edit"))
);
const TravelAwardApplications = Loadable(
  lazy(() => import("./modules/awards/pages/travel-award-applications"))
);
const TravelAwardDetail = Loadable(
  lazy(() => import("./modules/awards/pages/travel-award-detail"))
);

// Account pages

const Account = Loadable(lazy(() => import("./modules/general/pages/account")));

const OrcidValidation = Loadable(
  lazy(() => import("./modules/general/pages/orcid-validation"))
);

// Error pages

const NotFound = Loadable(lazy(() => import("./modules/errors/not-found")));

const routes = [
  {
    path: "home",
    element: <PublicLayout />,
    children: [
      { path: "", element: <Navigate to="/home/welcome" /> },
      { path: "welcome", element: <Home /> },
    ],
  },
  {
    path: "authentication",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/authentication/login" />,
      },
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "change-password/:token",
        element: <ChangePassword />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "verify-account/:token",
        element: <AccountVerification />,
      },
    ],
  },
  /** Redirections from old system routers */
  {
    path: "submit-manuscript/:id",
    element: (
      <AuthGuard>
        <SubmitRedirection />
      </AuthGuard>
    ),
  },
  {
    path: "password-reset/:token",
    element: (
      <AuthGuard>
        <ChangePasswordRedirection />
      </AuthGuard>
    ),
  },
  {
    path: "registration-confirmed/:token",
    element: (
      <AuthGuard>
        <VerifyAccountRedirection />
      </AuthGuard>
    ),
  },
  {
    path: "/app/reviews/reviews/done/:reviewId",
    element: (
      <AuthGuard>
        <ReviewDoneDetailRedirect />
      </AuthGuard>
    ),
  },
  {
    path: "/app/reviews/assignments/asked/:transaction",
    element: (
      <AuthGuard>
        <ReviewAskedDetailRedirect />
      </AuthGuard>
    ),
  },
  {
    path: "/app/reviews/assignments/asked",
    element: (
      <AuthGuard>
        <Navigate replace to="/app/reviews/asked" />
      </AuthGuard>
    ),
  },
  {
    path: "/app/reviews/reviews/done",
    element: (
      <AuthGuard>
        <Navigate replace to="/app/reviews/done" />
      </AuthGuard>
    ),
  },
  {
    path: "login",
    element: (
      <AuthGuard>
        <Navigate replace to="/authentication/login" />
      </AuthGuard>
    ),
  },
  {
    path: "register",
    element: (
      <AuthGuard>
        <Navigate replace to="/authentication/register" />
      </AuthGuard>
    ),
  },
  {
    path: "author-menu/current-submissions",
    element: (
      <AuthGuard>
        <Navigate replace to="/app/submissions/current" />
      </AuthGuard>
    ),
  },
  {
    path: "reviewer-menu/assignment",
    element: (
      <AuthGuard>
        <Navigate replace to="/app/reviews/drafts" />
      </AuthGuard>
    ),
  },
  {
    path: "reviewer-menu/invitations",
    element: (
      <AuthGuard>
        <Navigate replace to="/app/reviews/asked" />
      </AuthGuard>
    ),
  },
  /** End redirections */
  {
    path: "review-reject/:token",
    element: <UnregisteredReviewReject />,
  },
  {
    path: "app",
    children: [
      {
        path: "general",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: "conferences", element: <Conferences /> },
          { path: "calendar", element: <Calendar /> },
          {
            path: "conferences/:event/initial-submission",
            element: <InitialSubmission />,
          },
          { path: "account", element: <Account /> },
          { path: "orcid/validation", element: <OrcidValidation /> },
          {
            path: "*",
            element: <Navigate to="/not-found" />,
          },
          { path: "", element: <Navigate to="/not-found" /> },
        ],
      },
      {
        path: "administration",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: "conferences", element: <ConferencesAdministration /> },
          { path: "conferences/create", element: <CreateConference /> },
          { path: "conferences/:id", element: <ConferenceDetail /> },
          { path: "conferences/:id/edit", element: <EditConference /> },
          { path: "conferences/create-tracks", element: <CreateTracks /> },
          { path: "privacy", element: <PrivacyPreferences /> },
          { path: "promotion", element: <ConferencePromotion /> },
        ],
      },
      {
        path: "statistics",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: "conferences/overview", element: <ConferencesOverview /> },
          {
            path: "conferences/manuscripts-evolution",
            element: <ManuscriptsEvolution />,
          },
          {
            path: "conferences/manuscripts-overview",
            element: <ManuscriptsOverview />,
          },
        ],
      },
      {
        path: "management",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: "dashboard", element: <Dashboard /> },
          { path: "tracks", element: <ManageTracks /> },
          { path: "submissions", element: <ManuscriptsManagement /> },
          { path: "invitations/reviews", element: <ReviewInvitationsMgmt /> },
          {
            path: "invitations/reviews/reviewers",
            element: <ReviewersInvitations />,
          },
          {
            path: "invitations/invite-reviewer/:eventId/:paperId",
            element: <InviteReviewers />,
          },
          {
            path: "invitations/invite-bidder/:trackId/:eventId",
            element: <InviteBidders />,
          },
          { path: "invitations/bids", element: <BidInvitationsMgmt /> },
          { path: "decisions/submissions", element: <SubmissionsDecision /> },
          { path: "decisions/bids", element: <BidsDecision /> },
          {
            path: "decisions/submissions/:trackId",
            element: <SubmissionsDecisionMgmt />,
          },
          {
            path: "decisions/bids/:trackId/:trackName",
            element: <BidsDecisionMgmt />,
          },
          {
            path: "*",
            element: <Navigate to="/not-found" />,
          },
          { path: "", element: <Navigate to="/not-found" /> },
        ],
      },
      {
        path: "bidding",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "bids/asked",
            element: <AskedBids />,
          },
        ],
      },
      {
        path: "reviews",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: "asked", element: <AskedReviews /> },
          {
            path: "asked/:transaction",
            element: <AskedAssignmentDetail />,
          },
          {
            path: "assignments/asked/perform-bid/:trackId",
            element: <PerformBid />,
          },
          { path: "drafts", element: <DraftReviews /> },
          { path: "review/:paperId", element: <ReviewManuscript /> },
          { path: "done", element: <DoneReviews /> },
          { path: "done/:reviewId", element: <ReviewDetail /> },
          {
            path: "*",
            element: <Navigate to="/not-found" />,
          },
          { path: "", element: <Navigate to="/not-found" /> },
        ],
      },
      {
        path: "submissions",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: "current", element: <CurrentSubmissions /> },
          {
            path: "detail/:id",
            element: <ManuscriptDetail />,
          },
          { path: "detail/:id/edit", element: <EditSubmission /> },
          {
            path: "detail/:id/final-submission",
            element: <FinalSubmission />,
          },
          {
            path: "detail/:id/sign-ieee-copyright",
            element: <CopyrightSignature />,
          },
          {
            path: "*",
            element: <Navigate to="/not-found" />,
          },
          { path: "", element: <Navigate to="/not-found" /> },
        ],
      },
      {
        path: "ieee-ies-sypa-assistance",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "new/:id",
            element: <TravelAwardApplication />,
          },
          {
            path: "edit/:paperId/:sypaId",
            element: <TravelAwardApplicationEdit />,
          },
          {
            path: "applications",
            element: <TravelAwardApplications />,
          },
          {
            path: "application/:id",
            element: <TravelAwardDetail />,
          },
        ],
      },
      {
        path: "society",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "technical-co-sponsorship-applications",
            element: <TCSRequestList />,
          },
          {
            path: "technical-co-sponsorship-applications/new",
            element: <TCSRequestCreate />,
          },
          {
            path: "technical-co-sponsorship-applications/edit/:id",
            element: <TCSRequestEdit />,
          },
          {
            path: "technical-co-sponsorship-applications/:id",
            element: <TCSRequestDetail />,
          },
          {
            path: "system-activation-applications",
            element: <SystemActivationApplications />,
          },
          {
            path: "system-activation-applications/new",
            element: <SystemActivationApplication />,
          },
          {
            path: "system-activation-applications/:id",
            element: <SystemActivationApplicationDetail />,
          },
          {
            path: "system-activation-applications/edit/:id",
            element: <SystemActivationApplicationEdit />,
          },
          {
            path: "operating-agreements/:id",
            element: <OperatingAgreementSigning />,
          },
          {
            path: "operating-agreements",
            element: <OperatingAgreements />,
          },
          {
            path: "sponsored-conference-applications/new",
            element: <SponsoredConferenceApplication />,
          },
          {
            path: "sponsored-conference-applications",
            element: <SponsoredConferenceApplications />,
          },
          {
            path: "sponsored-conference-applications/:id",
            element: <SponsoredConferenceApplicationDetail />,
          },
          {
            path: "sponsored-conference-applications/edit/:id",
            element: <SponsoredConferenceApplicationEdit />,
          },
          {
            path: "*",
            element: <Navigate to="/not-found" />,
          },
          { path: "", element: <Navigate to="/not-found" /> },
        ],
      },
      {
        path: "docs",
        element: <DocumentationLayout />,
        children: [
          { path: "welcome", element: <WelcomeSection /> },
          { path: "roles-hierarchy", element: <RolesHierarchySection /> },
          { path: "tutorials", element: <VideoTutorialSection /> },
          { path: "account", element: <AccountSection /> },
          { path: "forgot-password", element: <ForgotPasswordSection /> },
          { path: "submit-paper", element: <SubmitPaperSection /> },
          { path: "submit-final", element: <SubmitFinalSection /> },
          { path: "sign-copyright", element: <SignCopyrightSection /> },
          { path: "paper-detail", element: <PaperDetailSection /> },
          { path: "paper-detail", element: <PaperDetailSection /> },
          { path: "edit-paper", element: <EditPaperSection /> },
          { path: "withdraw-paper", element: <WithdrawPaperSection /> },
          { path: "paper-feedbacks", element: <PaperFeedbacksSection /> },
          { path: "review-invitation", element: <ReviewInvitationSection /> },
          { path: "review-assignment", element: <ReviewAssignmentSection /> },
          { path: "submit-review", element: <SubmitReviewSection /> },
          { path: "reviews-done", element: <ReviewsDoneSection /> },
          { path: "bids-decision", element: <BidsDecisionSection /> },
          { path: "recommendations", element: <RecommendationsSection /> },
          { path: "papers-decision", element: <SubmissionsDecisionSection /> },
          { path: "dashboard-overview", element: <DashboardOverviewSection /> },
          { path: "manage-tracks", element: <ManageTracksSection /> },
          { path: "manage-submissions", element: <ManageSubmissionsSection /> },
          { path: "invite-reviewers", element: <InviteReviewersSection /> },
          { path: "invite-bidders", element: <InviteBiddersSection /> },
          { path: "manage-invitations", element: <ManageInvitationsSection /> },
          { path: "change-track", element: <ChangeTrackSection /> },
          { path: "manage-conference", element: <ManageConferenceSection /> },
          { path: "support", element: <ContactSupportSection /> },
          { path: "faq", element: <FAQSection /> },
          { path: "", element: <Navigate to="/not-found" /> },
        ],
      },
      { path: "", element: <Navigate to="/not-found" /> },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/home/welcome" />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
