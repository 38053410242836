import { Box, Button } from "@mui/material";
import UnexpectedErrorImage from "../../assets/unexpected-error.jpg";
import { SOCIETY_EMAIL } from "../../theme/societies";

const DashboardFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
      }}
    >
      <Box sx={{ mb: 10, mr: 10 }}>
        <img
          style={{ height: "50vh" }}
          alt="Unexpected error screen."
          src={UnexpectedErrorImage}
        />

        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            component="a"
            color="primary"
            variant="outlined"
            href={`mailto:${
              SOCIETY_EMAIL[process.env.REACT_APP_IEEE_SOCIETY]
            }?Subject=Something went wrong with Conferences Community&body=Error: ${
              error.stack
            }`}
          >
            Contact support
          </Button>
          <Button
            color="corporate"
            variant="contained"
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardFallback;
