export const SOCIETIES = {
  IES: "ies",
  EDS: "eds",
};

export const SOCIETY_EMAIL = {
  ies: "conferences@ieee-ies.org",
  eds: "conferences@ieee-eds.org",
};

export const LOGO_VARIANTS = {
  WHITE: "white",
  DEFAULT: "default",
};

export const SOCIAL_MEDIA = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
  YOUTUBE: "youtube",
  INSTAGRAM: "instagram",
};

export const SOCIAL_MEDIA_LINKS = {
  ies: {
    facebook: "https://www.facebook.com/IndustrialElectronicsSociety",
    twitter: "https://twitter.com/ieee_ies",
    linkedin: "https://www.linkedin.com/groups/3124640",
    youtube: "https://www.youtube.com/channel/UCKg8GNii0Q-ieXE56AXosGg",
    instagram: "https://www.instagram.com/ieeeorg",
  },
  eds: {
    facebook: "https://www.facebook.com/IEEEElectronDevicesSociety",
    twitter: "https://twitter.com/IEEEEDS",
    linkedin: "https://www.linkedin.com/company/ieeeelectrondevicessociety",
    youtube: "https://ieeetv.ieee.org/electron-devices-society",
    instagram: "https://www.instagram.com/ieee_eds",
  },
};
