import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(role, description, scope, obtain) {
  return { role, description, scope, obtain };
}

const rows = [
  createData(
    "User",
    "User is the basic role of the application, every registered (and validated) user of the application has this role. This role allows them to check basic information available in the application.",
    "Application",
    "Sign up and confirm the email used for registration."
  ),
  createData(
    "Author",
    "An author is anyone that has submitted a manuscript for a conference. This role allows operations related to manuscript submission management, eg: edit or retire the submission, submit new versions, etc.",
    "Conference",
    "A user automatically gets this role when they submit a manuscript to a conference."
  ),
  createData(
    "Bidder",
    "A bidder is anyone that has been invited to offer themselves for manuscript reviews. This role allows them to apply as reviewers for manuscripts in a track, special session or conference, depending on the range they have been allowed for. This application must be then approved by someone in charge of assigning reviewers in order to obtain the Reviewer role.",
    "Conference, Track or Special Session",
    "Assigned after an invitation to bid by a SSO (Special Session), TC (Track), CTC (Track), TPC (Track) or GC (Conference)."
  ),
  createData(
    "Reviewer",
    "A reviewer is anyone in charge of reviewing a certain manuscript. This role allows him to perform operations related to reviewing, such as checking the manuscript he has been assigned to, creating reviews, and editing or removing them.",
    "Manuscript",
    "Assigned after an invitation to review by a SSO (Special Session), TC (Track), CTC (Track), TPC (Track) or GC (Conference) or assigned after the bid for a manuscript gets approved by a SSO (Special Session), TC (Track), CTC (Track), TPC (Track) or GC (Conference)."
  ),
  createData(
    "Track Chair",
    "A Track Chair (TC) is anyone in charge of management tasks for a conference track. This role allows to, in the context of the track, perform operations related to said management, such as inviting bidders and reviewers, perform operations as another user (submission and reviewing related), to review manuscripts as TC, to recommend approval or rejection of manuscripts, and to send notifications related to the track.",
    "Track",
    "Assigned by a STC, a TPC, a GC, an Society Officer or an Administrator."
  ),
  createData(
    "Special Session Chair",
    "ASpecial Session Chair is a user in charge of managing the submitted content for a special sessions. Users with this role are allowed to recommend the acceptance of submitted manuscripts, and perform management tasks for the conference special sessions tracks, such as assigning user roles for these tracks.",
    "Special sessions",
    "Assigned by a TPC, a GC, an Society Officer or an Administrator."
  ),
  createData(
    "Technical Program Chair",
    "A Technical Program Chair is a user in charge of managing the submitted content for a conference. Users with this role are allowed to accept or reject submitted manuscripts, and perform management tasks for the conference tracks, such as assigning user roles for these tracks.",
    "Conference",
    "Assigned by a GC, an Society Officer or an Administrator."
  ),
  createData(
    "General Chair",
    "A General Chair is the main user in charge of managing a conference. This role gets permissions to perform any action related to a conference, including setting up the conference (information, dates), assigning user roles, viewing all the conference content (except in those cases where a COI is involved), etc.",
    "Conference",
    "Assigned either by an Society Officer or an Administrator at the moment a new conference is created."
  ),
];

export default function RoleList() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Scope</TableCell>
            <TableCell>How to Obtain</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.role}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.role}
              </TableCell>
              <TableCell align="justify">{row.description}</TableCell>
              <TableCell align="justify">{row.scope}</TableCell>
              <TableCell align="justify">{row.obtain}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
