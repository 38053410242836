import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import * as constants from "../../constants";
import {
  LOGO_VARIANTS,
  SOCIAL_MEDIA,
  SOCIAL_MEDIA_LINKS,
  SOCIETIES,
  SOCIETY_EMAIL,
} from "../../theme/societies";
import SocietyLogo from "../../components/society-logo";
import { format } from "date-fns";

const IES_CONTACT = "http://www.ieee-ies.org/home/contact";
const EDS_CONTACT = "https://eds.ieee.org/about-eds/eds-executive-office";

const society = process.env.REACT_APP_IEEE_SOCIETY;

const contactLink = society === SOCIETIES.IES ? IES_CONTACT : EDS_CONTACT;

const currentYear = format(new Date(), "yyyy");

const copyrightText = `© Copyright ${currentYear} IEEE - All rights reserved. A not-for-profit organization, IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity.`;

const societyCopyrightText = `${currentYear} ${society.toUpperCase()} Conferences Community`;

const sections = [
  {
    title: "Links",
    links: [
      {
        title: "Documentation",
        href: "/app/docs/welcome",
      },
      ...(society === SOCIETIES.IES
        ? [
            {
              title: "Technical Co-sponsorship",
              href: "/app/society/technical-co-sponsorship-applications/new",
            },
            {
              title: "Request for Submission System",
              href: "/app/society/system-activation-applications/new",
            },
            {
              title: "Sponsored Conferences",
              href: "/app/society/sponsored-conference-applications/new",
            },
          ]
        : []),
    ],
  },
  {
    title: "Placeholders",
    links: [
      {
        title: "IEEE Sitemap",
        href: constants.IEEE_SITEMAP,
      },
      {
        title: "Contact Society",
        href: contactLink,
      },
      {
        title: "Accessibility",
        href: constants.IEEE_ACCESSIBILITY,
      },
      {
        title: "Nondiscrimination Policy",
        href: constants.IEEE_NONDISCRIMINATION_POLICY,
      },
      {
        title: "IEEE Ethics Reporting",
        href: constants.IEEE_ETHICS_REPORTING,
      },
      {
        title: "IEEE Privacy Policy",
        href: constants.IEEE_PRIVACY_POLICY,
      },
      {
        title: "Terms",
        href: constants.IEEE_TERMS,
      },
      {
        title: `Support (${SOCIETY_EMAIL[society]})`,
        href: `mailto:${SOCIETY_EMAIL[society]}?Subject=Conferences Community support`,
      },
    ],
  },
  {
    title: "Social",
    links: [
      {
        title: "Facebook",
        href: SOCIAL_MEDIA_LINKS[society][SOCIAL_MEDIA.FACEBOOK],
      },
      {
        title: "Instagram",
        href: SOCIAL_MEDIA_LINKS[society][SOCIAL_MEDIA.INSTAGRAM],
      },
      {
        title: "Twitter",
        href: SOCIAL_MEDIA_LINKS[society][SOCIAL_MEDIA.TWITTER],
      },
      {
        title: "LinkedIn",
        href: SOCIAL_MEDIA_LINKS[society][SOCIAL_MEDIA.LINKEDIN],
      },
      {
        title: "Youtube",
        href: SOCIAL_MEDIA_LINKS[society][SOCIAL_MEDIA.YOUTUBE],
      },
    ],
  },
];

const PublicFooter = () => (
  <Box
    sx={{
      backgroundColor: "background.default",
      borderTopColor: "divider",
      borderTopStyle: "solid",
      borderTopWidth: 1,
      pb: 6,
      pt: {
        md: 15,
        xs: 6,
      },
    }}
  >
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            order: {
              md: 1,
              xs: 4,
            },
          }}
          xs={12}
        >
          <SocietyLogo variant={LOGO_VARIANTS.DEFAULT} />
          <Typography color="textSecondary" sx={{ mt: 1 }} variant="caption">
            {societyCopyrightText}
          </Typography>
        </Grid>
        {sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1,
              },
            }}
            xs={12}
          >
            <Typography color="textSecondary" variant="overline">
              {section.title}
            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1,
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      minWidth: 0,
                      mr: 0.5,
                    }}
                  >
                    <RemoveOutlinedIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {link.title}
                      </Link>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.12),
          my: 5,
        }}
      />
      <Typography color="textSecondary" variant="caption">
        {copyrightText}
      </Typography>
    </Container>
  </Box>
);

export default PublicFooter;
