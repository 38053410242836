import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Divider,
  Link,
  useTheme,
} from "@mui/material";
import { LoginForm } from "../components";
import IeeeLogo from "../../../components/ieee-logo";

const Login = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Login | Conferences Community</title>
      </Helmet>

      <Container maxWidth="sm" sx={{ py: "90px" }}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Log in
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Log in on the internal conferences platform
                </Typography>
              </div>

              <Box
                sx={{
                  "& > img": {
                    maxHeight: "100%",
                    width: "auto",
                  },
                }}
              >
                <Tooltip title="Institute of Electrical and Electronics Engineers">
                  <IeeeLogo />
                </Tooltip>
              </Box>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <LoginForm />
            </Box>

            <Divider sx={{ my: 3 }} />

            <Link
              color="textSecondary"
              component={RouterLink}
              to="/authentication/password-recovery"
              variant="body2"
            >
              Forgot password
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Login;
