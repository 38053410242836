import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inviteReviewers: [],
};

const slice = createSlice({
  name: "inviteReviewers",
  initialState,
  reducers: {
    addReviewer(state, action) {
      const { user } = action.payload;
      state.inviteReviewers = [...state.inviteReviewers, user];
    },
    editReviewer(state, action) {
      const { user } = action.payload;
      const intersection = state.inviteReviewers.filter(
        (reviewer) => reviewer.email !== user.email
      );
      state.inviteReviewers = [...intersection, user];
    },
    deleteReviewer(state, action) {
      const { email } = action.payload;
      const intersection = state.inviteReviewers.filter(
        (reviewer) => reviewer.email !== email
      );
      state.inviteReviewers = [...intersection];
    },
    resetReviewers(state, action) {
      const { paperId } = action.payload;
      const intersection = state.inviteReviewers.filter(
        (reviewer) => reviewer.paperId !== paperId
      );
      state.inviteReviewers = [...intersection];
    },
  },
});

export const addReviewer = (user) => async (dispatch) => {
  dispatch(slice.actions.addReviewer({ user }));
};

export const editReviewer = (user) => async (dispatch) => {
  dispatch(slice.actions.editReviewer({ user }));
};

export const deleteReviewer = (email) => async (dispatch) => {
  dispatch(slice.actions.deleteReviewer({ email }));
};

export const resetReviewers = (paperId) => async (dispatch) => {
  dispatch(slice.actions.resetReviewers({ paperId }));
};

export const { reducer } = slice;

export default slice;
